import React, { useEffect } from 'react'

import StaticPage from '../../../components/StaticPage/StaticPage'

const Privacy = ({ page, onLoadData }) => {
  useEffect(() => {
    onLoadData()
  }, [onLoadData])

  return <StaticPage page={page} />
}

export default Privacy
