import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logo from 'assets/images/logo.png'
import { MaxWidthContainer } from 'components'
import { MenuItem, SocialLinkItem } from 'types'

import { MainMenu } from './MainMenu'
import { SocialLinks } from './SocialLinks'

const StyledHeader = styled.header`
  position: fixed;
  inset-inline: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: solid ${({ theme }) => theme.colors.highlight}
    ${({ theme }) => theme.border.thickness.large};
`

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.small};
`

const Logo = styled.div`
  align-self: flex-end;
  padding-block-start: 0.5em;
`

const Image = styled.img`
  max-height: 3em;
`

const ContainerRight = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.medium};
`

type HeaderProps = {
  mainMenu: MenuItem[]
  socialLinks: SocialLinkItem[]
  onLoadData: () => void
}

const Header: React.FC<HeaderProps> = ({
  mainMenu,
  socialLinks,
  onLoadData
}) => {
  useEffect(() => {
    onLoadData()
  }, [onLoadData])

  return (
    <StyledHeader>
      <MaxWidthContainer>
        <HeaderContainer>
          <Logo>
            <Link to='/'>
              <Image src={logo} />
            </Link>
          </Logo>
          <ContainerRight>
            <MainMenu menuLinks={mainMenu} />
            <SocialLinks socialLinks={socialLinks} />
          </ContainerRight>
        </HeaderContainer>
      </MaxWidthContainer>
    </StyledHeader>
  )
}

export default Header
