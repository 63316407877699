import './BasicInput.scss'

const BasicInput = ({ className, label, htmlFor, children }) => {
  return (
    <div className={className}>
      {label !== '' && <label htmlFor={htmlFor}>{label} </label>}
      {children}
    </div>
  )
}

export default BasicInput
