import { FETCH_SOCIAL_LINKS } from '../actions/types'

const initialState = {
  socialLinks: []
}

export const socialLinksReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOCIAL_LINKS:
      return {
        ...state,
        socialLinks: action.payload
      }
    default:
      return state
  }
}
