import BasicInput from './BasicInput'

const EmailInput = ({
  className,
  label,
  onChange,
  value,
  name,
  placeholder
}) => {
  return (
    <BasicInput className={className} label={label} htmlFor={name}>
      <input
        type='email'
        value={value}
        onChange={(evt) => {
          onChange(evt)
        }}
        name={name}
        placeholder={placeholder}
      />
    </BasicInput>
  )
}

export default EmailInput
