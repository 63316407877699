import { connect } from 'react-redux'
import {
  fetchPostIndividual,
  fetchPostsOverview
} from '../../../../actions/posts-actions'
import NewsIndividual from './NewsIndividual'

const mapStateToProps = (state) => {
  return state.posts
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (slug) => {
      dispatch(fetchPostIndividual(slug))
      dispatch(fetchPostsOverview())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsIndividual)
