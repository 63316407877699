export const FETCH_PAGES_OVERVIEW = 'FETCH_PAGES_OVERVIEW'
export const FETCH_PAGE_INDIVIDUAL = 'FETCH_PAGE_INDIVIDUAL'

export const FETCH_PLAYS_OVERVIEW = 'FETCH_PLAYS_OVERVIEW'
export const FETCH_PLAYS_UPCOMING = 'FETCH_PLAYS_UPCOMING'
export const FETCH_PLAY_INDIVIDUAL = 'FETCH_PLAY_INDIVIDUAL'
export const FETCH_PLAYS_SOLR_RESULT = 'FETCH_PLAYS_SOLR_RESULT'

export const FETCH_PERSONS_OVERVIEW = 'FETCH_PERSONS_OVERVIEW'
export const FETCH_PERSON_INDIVIDUAL = 'FETCH_PERSON_INDIVIDUAL'

export const FETCH_POSTS_OVERVIEW = 'FETCH_POSTS_OVERVIEW'
export const FETCH_POST_INDIVIDUAL = 'FETCH_POST_INDIVIDUAL'

export const FETCH_ACTIVITIES_OVERVIEW = 'FETCH_ACTIVITIES_OVERVIEW'
export const FETCH_ACTIVITIES_INDIVIDUAL = 'FETCH_ACTIVITIES_INDIVIDUAL'

export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM'

export const FETCH_MENUS = 'FETCH_MENUS'

export const FETCH_SOCIAL_LINKS = 'FETCH_SOCIAL_LINKS'
