import { FETCH_MENUS } from '../actions/types'

const initialState = {
  mainMenu: [],
  footerMenu: []
}

export const menusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENUS:
      return {
        ...state,
        mainMenu: action.payload.find((node) => node.slug === 'hauptmenue')
          .menuItems.nodes,
        footerMenu: action.payload.find((node) => node.slug === 'footer')
          .menuItems.nodes
      }
    default:
      return state
  }
}
