import BasicInput from './BasicInput'

const SelectInput = ({
  onChange,
  className,
  label,
  name,
  id,
  value,
  options
}) => {
  return (
    <BasicInput className={className} label={label} htmlFor={name}>
      <select id={id} name={name} defaultValue={value} onChange={onChange}>
        {options.map((option, i) => {
          return <option key={i}>{option}</option>
        })}
      </select>
    </BasicInput>
  )
}

export const YearInput = (props) => {
  const yearOptions = []

  for (let year = props.from; year <= props.to; year++) {
    yearOptions.push(year)
  }

  return <SelectInput {...props} options={yearOptions} />
}

export default SelectInput
