import React, { useEffect } from 'react'

import Heading from '../../../../components/Heading/Heading'
import LoadingCircle from '../../../../components/LoadingCircle/LoadingCircle'
import PlaysGallery from '../../../../components/PlaysGallery/PlaysGallery'

const PlaysOverview = ({ plays, onLoadData }) => {
  useEffect(() => {
    onLoadData()
  }, [onLoadData])

  if (plays.length === 0) return <LoadingCircle />

  plays.forEach(
    (play) => play['person-details'] && delete play['person-details']
  )

  return (
    <>
      <section className='plays sectioncontainer'>
        <Heading title='Stücke' topTitle='Übersicht' />
        <PlaysGallery plays={plays} />
      </section>
    </>
  )
}

export default PlaysOverview
