import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonGhost } from '../../Button/Button'
import styles from './GalleryItem.module.scss'
import classNames from 'classnames'

const Item = ({ item, showTitles }) => (
  <>
    <div
      style={
        item.url // TODO use thumbnails?
          ? { backgroundImage: `url(${item.url})`, backgroundSize: 'cover' }
          : {}
      }
      className={styles['gallery-item__image']}
    >
      {item.overlay && (
        <div className={styles['gallery-item__overlay']}>
          <div className={styles['gallery-item__overlay-text']}>
            {item.overlay.besetzung?.length > 0 && (
              <div className={styles['gallery-item__roles']}>
                <h3 className='h3--color-white'>
                  {item.overlay.besetzung.length > 1 ? 'Rollen' : 'Rolle'}
                </h3>
                {item.overlay.besetzung.map((p) => (
                  <p key={p} className='p--color-white'>
                    {p || 'Unbekannt'}
                  </p>
                ))}
              </div>
            )}

            {item.overlay.weiterePersonen?.length > 0 && (
              <div className={styles['gallery-item__functions']}>
                <h3 className='h3--color-white'>
                  {item.overlay.weiterePersonen.length > 1
                    ? 'Funktionen'
                    : 'Funktion'}
                </h3>
                <p className='p--color-white'>
                  {item.overlay.weiterePersonen.map((p) => (
                    <p key={p} className='p--color-white'>
                      {p || 'Unbekannt'}
                    </p>
                  ))}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
    {showTitles && item.title && (
      <div className={classNames(styles['gallery-item__title'], 'p--size-big')}>
        {item.title}
      </div>
    )}
  </>
)

const GalleryItem = ({ item, showTitles = true, types = '' }) => {
  const myStyles = types
    .split(' ')
    .map((type) => styles[type])
    .join(' ')

  return (
    <article className={styles['gallery-item'] + ' ' + myStyles}>
      {item.routeTo ? (
        <Link
          to={item.routeTo}
          className={styles['gallery-item__link'] + ' p--style-bold'}
        >
          <Item item={item} showTitles={showTitles} />
        </Link>
      ) : (
        <Item item={item} showTitles={showTitles} />
      )}
      {showTitles && item.subtitle && (
        <p className='p--size-small p--color-grey'>{item.subtitle}</p>
      )}
      {item.content && (
        <div
          className={styles['gallery-item__content']}
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      )}
      {item.readMore && (
        <ButtonGhost centered={true} type='link' link={item.routeTo}>
          Weiterlesen
        </ButtonGhost>
      )}
    </article>
  )
}

export default GalleryItem
