import React from 'react'
import './ExternalLink.module.scss'

const ExternalLink = ({ children, href, styling }) => (
  <a href={href} className={styling}>
    {children}
  </a>
)

export default ExternalLink
