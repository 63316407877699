import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'

import { usePageTitle } from 'hooks'

import ImageGallery from './ImageGallery/ImageGallery'
import Participants from './Participants/Participants'
import PressArticles from './PressArticles/PressArticles'
import Shows from './Shows/Shows'
import VideoRecording from './Videos/VideoRecording'
import Videos from './Videos/Videos'
import Hero from '../../../../components/Hero/Hero'
import LoadingCircle from '../../../../components/LoadingCircle/LoadingCircle'
import LocationMap from '../../../../components/LocationMap/LocationMap'
import PlayContent from '../../../../components/PlayContent/PlayContent'
import PlayCurrentInformation from '../../../../components/PlayCurrentInformation/PlayCurrentInformation'
import PlayStatistics from '../../../../components/PlayStatistics/PlayStatistics'
import { getTheaterTypEmoji } from '../../../../helpers/TheaterTypHelper'

const PlayIndividual = ({ plays, play: paramPlay, onLoadData }) => {
  const { slug } = useParams()

  const [play, setPlay] = useState(paramPlay)

  usePageTitle(play.title)

  useEffect(() => {
    setPlay(paramPlay)
  }, [paramPlay])

  useEffect(() => {
    // load play if necessary
    if (play?.slug !== slug) {
      // search for play in all loaded plays
      const foundPlay = plays.find((existingPlay) => existingPlay.slug === slug)
      if (foundPlay) {
        setPlay(foundPlay)
      } else {
        // if play is not found, load individual play from backend
        onLoadData(slug)
      }
    }
  }, [onLoadData, play?.slug, plays, slug])

  if (!play?.title) return <LoadingCircle />

  return play.acf.istAktuellesStuck ? (
    <PlayIndividualUpcoming play={play} />
  ) : (
    <PlayIndividualPast play={play} />
  )
}

export default PlayIndividual

const PlayIndividualUpcoming = ({ play }) => (
  <>
    <Hero
      imageSrc={play.acf.beitragsbild.url}
      title={`${play.acf.jahr} - ${play.title}`}
      subtitle={`${play.acf.genre} von ${play.acf.autor}`}
      emoji={getTheaterTypEmoji(play.acf.typ)}
    />
    {play.acf.aktuelleInfos && (
      <PlayCurrentInformation currentInformation={play.acf.aktuelleInfos} />
    )}
    <PlayContent play={play} />
    <Shows upcoming={true} ticketsAb={play.acf.ticketsAb}>
      {play.acf.auffuhrungen}
    </Shows>
    <ImageGallery images={play.acf.fotos} />
    <Videos videos={play.acf.videos} />
    <Participants personen={play.acf.personen} />
    <LocationMap />
  </>
)

const PlayIndividualPast = ({ play }) => (
  <>
    <Hero
      imageSrc={play.acf.beitragsbild.url}
      title={play.title}
      subtitle={`${play.acf.genre} von ${play.acf.autor}`}
      emoji={getTheaterTypEmoji(play.acf.typ)}
    />
    <PlayContent play={play} />
    <PlayStatistics play={play} />
    <Shows upcoming={false}>{play.acf.auffuhrungen}</Shows>
    <Videos videos={play.acf.videos} />
    {play.acf.aufzeichnung && (
      <VideoRecording
        password={play.acf.aufzeichnungspasswort}
        link={play.acf.aufzeichnung}
        payLink={play.acf.kauflink}
      />
    )}
    <Participants personen={play.acf.personen} />
    <ImageGallery images={play.acf.fotos} />
    <PressArticles images={play.acf.presseberichte} />
  </>
)
