import { useFormik } from 'formik'
import styles from './SearchFilter.module.scss'
import TextInput from '../Input/TextInput'
import RadioInput from '../Input/RadioInput'
import SelectInput, { YearInput } from '../Input/SelectInput'
import { ButtonGhost, ButtonMain } from '../Button/Button'
import { CollapseFilter } from '../CollapseContent/CollapseContent'
import { useState } from 'react'

const Search = ({ formik }) => (
  <div className={styles.search}>
    <TextInput
      className={styles.search__input}
      label=''
      name='search-text'
      value={formik.values['search-text']}
      onChange={formik.handleChange}
      placeholder='Suche eingeben ...'
    />
    <div className={styles.search__button}>
      <ButtonMain type='submit'>Suchen</ButtonMain>
    </div>
  </div>
)

const Filter = ({ formik, yearSpan, category }) => (
  <div className={styles.filter}>
    <h3>Stück-Kategorie</h3>
    <div className={styles.filter__category}>
      <RadioInput
        className={styles.filter__words}
        values={category}
        group={'filter-type'}
        formikValues={formik.values}
      />
    </div>

    <h3>Zeitraum</h3>
    <div className={styles.filter__date}>
      <YearInput
        className={styles.filter__year}
        label='von'
        name='filter-year-from'
        id='filter-year-from'
        value={formik.values['filter-year-from']}
        onChange={formik.handleChange}
        from={yearSpan.from}
        to={yearSpan.to}
      />

      <YearInput
        className={styles.filter__year}
        label='bis'
        name='filter-year-to'
        id='filter-year-to'
        value={formik.values['filter-year-to']}
        onChange={formik.handleChange}
        from={yearSpan.from}
        to={yearSpan.to}
      />
    </div>
  </div>
)

const Sorting = ({ formik, sortingOptions }) => (
  <div className={styles.sorting}>
    <h3>Sortieren</h3>
    <SelectInput
      className={styles.sorting__input}
      label=''
      name='sort-by'
      id='sort-by'
      value={formik.values['sort-by']}
      onChange={formik.handleChange}
      options={sortingOptions}
    />
  </div>
)

const FilterSettings = ({ formik, yearSpan, category, sortingOptions }) => {
  const [openState, setOpenState] = useState(false)

  return (
    <div
      className={
        styles['filter-search-sorting__settings'] + ' collapse__filter'
      }
    >
      <CollapseFilter
        openStateObject={[openState, setOpenState]}
        header={<p>Filter</p>}
      >
        <div
          className={styles['filter-search-sorting__settings--status-opened']}
        >
          <Sorting formik={formik} sortingOptions={sortingOptions} />

          <Filter formik={formik} yearSpan={yearSpan} category={category} />

          <ButtonGhost
            centered={true}
            type='submit'
            onClick={() => {
              setOpenState(false)
            }}
          >
            Fertig
          </ButtonGhost>
        </div>
      </CollapseFilter>
    </div>
  )
}

const FilterSelected = ({ initialValues, formikValues }) => {
  return (
    <div className={styles['filter-search-sorting__active']}>
      {/* TODO: bessere Lösung? */}
      {checkValueChange(initialValues['sort-by'], formikValues['sort-by']) && (
        <p>{formikValues['sort-by']}</p>
      )}

      {checkValueChange(
        initialValues['filter-type'],
        formikValues['filter-type']
      ) && <p>{getFilterType(formikValues['filter-type'])}</p>}

      {(checkValueChange(
        initialValues['filter-year-from'],
        formikValues['filter-year-from']
      ) ||
        checkValueChange(
          initialValues['filter-year-to'],
          formikValues['filter-year-to']
        )) && (
        <p>
          {formikValues['filter-year-from']} - {formikValues['filter-year-to']}
        </p>
      )}
    </div>
  )
}

// TODO: Code Duplizierung siehe solr.js
function getFilterType(id) {
  let filterType = ''
  switch (id) {
    case 0:
      filterType = 'Alle'
      break
    case 1:
      filterType = 'Kindertheater'
      break
    case 2:
      filterType = 'Erwachsenentheater'
      break
  }
  return filterType
}

function checkValueChange(initialValue, formikValue) {
  return initialValue !== formikValue
}

const SearchFilter = ({ onReloadData, yearSpan, category, sortingOptions }) => {
  const initialValuesSaved = {
    'search-text': '',
    'filter-type': 0,
    'filter-year-from': yearSpan.from,
    'filter-year-to': yearSpan.to,
    'sort-by': 'Relevanz'
  }

  const formik = useFormik({
    initialValues: {
      ...initialValuesSaved
    },
    onSubmit: (values) => {
      onReloadData(values)
    }
  })

  return (
    <section className={styles + ' sectioncontainer maxwidthcontainer'}>
      <form
        className={styles['filter-search-sorting']}
        onSubmit={formik.handleSubmit}
      >
        <Search formik={formik} />
        <FilterSettings
          formik={formik}
          yearSpan={yearSpan}
          category={category}
          sortingOptions={sortingOptions}
        />
      </form>
      <FilterSelected
        initialValues={initialValuesSaved}
        formikValues={formik.values}
      />
    </section>
  )
}

export default SearchFilter
