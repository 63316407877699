import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'

import { usePageTitle } from 'hooks'

import { ButtonMain } from '../../../../../components/Button/Button'
import GalleryComponent from '../../../../../components/Gallery/GalleryComponent/GalleryComponent'
import Heading from '../../../../../components/Heading/Heading'
import LoadingCircle from '../../../../../components/LoadingCircle/LoadingCircle'
import SingleContent from '../../../../../components/SingleContent/SingleContent'
import DatetimeHelper from '../../../../../helpers/DatetimeHelper'

const ActivitiesIndividual = ({
  activity: paramActivity,
  activities,
  onLoadData
}) => {
  const { slug } = useParams()

  const [activity, setActivity] = useState(paramActivity)

  usePageTitle(activity.title)

  useEffect(() => {
    setActivity(paramActivity)
  }, [paramActivity])

  useEffect(() => {
    // load activity if necessary
    if (activity?.slug !== slug) {
      // search for activity in all loaded activities
      const foundActivity = activities.find(
        (existingActivity) => existingActivity.slug === slug
      )
      if (foundActivity) {
        setActivity(foundActivity)
      } else {
        // if activity is not found, load individual activity from backend
        onLoadData(slug)
      }
    }
  }, [activities, activity?.slug, onLoadData, slug])

  if (!activity?.title) return <LoadingCircle />

  const otherActivities = activities
    .filter((activitiesListItem) => activitiesListItem.id !== activity.id)
    .sort((_a, _b) => 0.5 - Math.random()) // random shuffle
    .slice(0, 3)

  const vonDate = new DatetimeHelper(activity.acf.zeitraum.von)
  const bisDate = new DatetimeHelper(activity.acf.zeitraum.bis)

  const showBisDate =
    activity.acf.zeitraum.bis &&
    activity.acf.zeitraum.bis !== activity.acf.zeitraum.von

  const subTitle = showBisDate
    ? `${vonDate.getShortDateString(false)} - ${bisDate.getShortDateString()}`
    : `${vonDate.getShortDateString()}`

  return (
    <div>
      <Heading
        title={activity.acf.name}
        topTitle='Aktivitäten'
        subTitle={subTitle}
      />

      <SingleContent item={activity} />

      <section className='smallcontainer sectioncontainer'>
        {otherActivities.length && (
          <>
            <h2>Weitere Aktivitäten</h2>
            <GalleryComponent
              galleryItems={otherActivities.map((activity) => ({
                id: activity.id,
                title: activity.acf.name,
                subtitle: new DatetimeHelper(
                  activity.acf.zeitraum.von
                ).getYear(),
                url: activity.featuredImage?.node?.sourceUrl,
                routeTo: `/aktivitaten/${activity.slug}`
              }))}
            />
          </>
        )}

        <ButtonMain type='link' link='/ueber-uns' centered={true}>
          Zurück
        </ButtonMain>
      </section>
    </div>
  )
}

export default ActivitiesIndividual
