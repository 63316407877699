import {
  FETCH_PLAYS_OVERVIEW,
  FETCH_PLAYS_UPCOMING,
  FETCH_PLAYS_SOLR_RESULT,
  FETCH_PLAY_INDIVIDUAL
} from '../actions/types'

const initialState = {
  plays: [],
  upcomingPlays: [],
  play: {},
  searchResult: {},
  spellingCorrection: {}
}

export const playsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAYS_OVERVIEW:
      return {
        ...state,
        plays: action.payload
      }
    case FETCH_PLAYS_UPCOMING:
      return {
        ...state,
        upcomingPlays: action.payload
      }
    case FETCH_PLAYS_SOLR_RESULT:
      if (action.appendData) {
        state.searchResult.response.docs.push(...action.payload.response.docs)
        state.searchResult.highlighting = {
          ...state.searchResult.highlighting,
          ...action.payload.highlighting
        }
        return {
          ...state,
          searchResult: { ...state.searchResult },
          spellingCorrection: action.spellingCorrection
        }
      }

      return {
        ...state,
        searchResult: action.payload,
        spellingCorrection: action.spellingCorrection
      }
    case FETCH_PLAY_INDIVIDUAL:
      return {
        ...state,
        play: action.payload
      }
    default:
      return state
  }
}
