import { FETCH_POST_INDIVIDUAL, FETCH_POSTS_OVERVIEW } from './types'
import { graphqlUrl } from './api'
import { request } from 'graphql-request'
import { postsQuery } from './graphqlQueries'

export const fetchPostsOverview = () => async (dispatch) => {
  return await request(graphqlUrl, postsQuery()).then((response) =>
    dispatch({
      type: FETCH_POSTS_OVERVIEW,
      payload: response.posts.nodes
    })
  )
}

export const fetchPostIndividual = (slug) => async (dispatch) => {
  return await request(graphqlUrl, postsQuery(slug)).then((response) =>
    dispatch({
      type: FETCH_POST_INDIVIDUAL,
      payload: response.posts.nodes[0]
    })
  )
}
