import { connect } from 'react-redux'
import Contact from './Contact'
import { sendContactForm } from '../../../actions/contact-actions'

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitData: (values) => dispatch(sendContactForm(values))
  }
}

export default connect(null, mapDispatchToProps)(Contact)
