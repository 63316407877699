import React from 'react'

import { Link } from 'react-router-dom'

import styles from './SearchItem.module.scss'

const ItemImage = ({ item }) => (
  <>
    <div
      className={styles['search-item__image']}
      style={
        item.url
          ? { backgroundImage: `url(${item.url})`, backgroundSize: 'cover' }
          : {}
      }
    />
  </>
)

const SearchItem = ({ item, showTitles = true }) => {
  return (
    <article className={styles['search-item']}>
      {item.routeTo ? (
        <Link
          to={item.routeTo}
          className={styles['search-item__link'] + ' p--style-bold'}
        >
          <ItemImage item={item} />
        </Link>
      ) : (
        <ItemImage item={item} />
      )}

      <div className={styles['search-item__main']}>
        <Link
          to={item.routeTo}
          className={styles['search-item__link'] + ' p--style-bold'}
        >
          {showTitles && item.title && (
            <h3 className={styles['search-item__title'] + ' h3--style-bold'}>
              {item.title}
            </h3>
          )}
        </Link>

        {showTitles && item.subtitle && (
          <p className='p--size-small p--color-grey'>{item.subtitle}</p>
        )}

        {item.content && (
          <div className={styles['search-item__content']}>{item.content}</div>
        )}
      </div>
    </article>
  )
}

export default SearchItem
