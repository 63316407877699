const googleMapsEmbedLink =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d669.4712848160141!2d13.02743702926087!3d47.841818498822384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47769b6ff28255cd%3A0x649abd1acee031ad!2sTheatergruppe%20Bergheim!5e0!3m2!1sde!2sat!4v1619827680458!5m2!1sde!2sat'

const LocationMap = ({ locationLink = googleMapsEmbedLink }) => (
  <section className='location' style={{ marginTop: '1em' }}>
    <iframe
      src={locationLink}
      width={document.documentElement.clientWidth}
      height={document.documentElement.clientHeight / 2}
      loading='lazy'
    ></iframe>
  </section>
)

export default LocationMap
