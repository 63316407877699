import React from 'react'

import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { theme } from 'assets'
import ScrollToTop from 'helpers/ScrollToTop'

import Footer from './containers/layout/footer'
import { Header } from './containers/layout/header'
import Main from './containers/layout/main'

import './assets/scss/style.scss'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Header />
        <Main />
        <Footer />
      </Router>
    </ThemeProvider>
  )
}

export default App
