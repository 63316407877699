export const theme = {
  colors: {
    white: '#ffffff',
    lightgrey: '#f9f6f6',
    grey: '#a0a0a0',
    highlight: '#ee964b'
  },
  spacing: {
    tiny: '0.5em',
    small: '1em',
    medium: '2em',
    big: '3.5em',
    large: '5em'
  },
  border: {
    radius: {
      small: '3px',
      medium: '5px',
      large: '10px'
    },
    thickness: {
      tiny: '0.5px',
      small: '1px',
      medium: '2px',
      large: '3px'
    }
  },
  devices: {
    desktop: 'screen and (min-width: 1081px)',
    tablet: 'screen and (max-width: 1080px)',
    mobile: 'screen and (max-width: 720px)'
  }
}
