import { connect } from 'react-redux'
import { fetchPostsOverview } from '../../../actions/posts-actions'
import {
  fetchPlaysUpcoming,
  fetchPlaysOverview
} from '../../../actions/plays-actions'
import Home from './Home'

const mapStateToProps = (state) => {
  return {
    ...state.posts,
    ...state.plays
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => {
      dispatch(fetchPlaysUpcoming())
      dispatch(fetchPostsOverview())
      dispatch(fetchPlaysOverview())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
