import React, { useEffect } from 'react'

import { useParams } from 'react-router'

import { usePageTitle } from 'hooks'

import PersonInfo from './PersonInfo/PersonInfo'
import PersonPlays from './PersonPlays/PersonPlays'
import LoadingCircle from '../../../../components/LoadingCircle/LoadingCircle'

const PersonIndividual = ({ plays, person, onLoadData }) => {
  const { slug } = useParams()

  usePageTitle(person.title)

  useEffect(() => {
    return onLoadData(slug)
  }, [onLoadData, slug])

  if (!(person?.slug === slug)) return <LoadingCircle />

  return (
    <>
      <PersonInfo person={person} />
      {plays.length === 0 && <LoadingCircle />}
      <PersonPlays person={person} plays={plays} />
    </>
  )
}

export default PersonIndividual
