import React, { useState } from 'react'

import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { IconButton } from 'components'
import { MenuItem } from 'types'

const MobileMenu = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.mobile} {
    display: flex;
  }
`

const DesktopMenu = styled.nav`
  display: flex;

  @media ${({ theme }) => theme.devices.mobile} {
    display: none;
  }
`

const MenuList = styled.ul`
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.tiny};

  @media ${({ theme }) => theme.devices.mobile} {
    position: absolute;
    inset-inline: 0;
    top: calc(100% + 3px);

    flex-direction: column;
    gap: 0;

    background-color: ${({ theme }) => theme.colors.white};
  }
`

const MenuListItem = styled.li`
  display: flex;

  @media ${({ theme }) => theme.devices.mobile} {
    width: 100%;
    justify-content: flex-end;
    border-bottom: 2px solid;
    padding-block: ${({ theme }) => theme.spacing.tiny};
    padding-right: ${({ theme }) => theme.spacing.small};
  }
`

const StyledNavLink = styled(NavLink)`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-inline: ${({ theme }) => theme.spacing.tiny};
  div {
    padding-block: ${({ theme }) => theme.spacing.tiny};
  }

  &.active,
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightgrey};

    @media ${({ theme }) => theme.devices.mobile} {
      background-color: transparent;
    }

    div {
      color: ${({ theme }) => theme.colors.highlight};
    }
  }
`

type MainMenuProps = {
  menuLinks: MenuItem[]
}

export const MainMenu: React.FC<MainMenuProps> = ({ menuLinks }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const menuListItems =
    menuLinks.length !== 0 &&
    menuLinks.map((item) => {
      return (
        <MenuListItem key={item.label}>
          <StyledNavLink
            to={item.path}
            className={'a--style-bold'}
            onClick={() => {
              setMenuOpen(false)
            }}
          >
            <div>{item.label}</div>
          </StyledNavLink>
        </MenuListItem>
      )
    })

  return (
    <>
      <MobileMenu>
        <IconButton
          iconClass='fas fa-bars'
          onClick={() => {
            setMenuOpen((open) => !open)
          }}
        />
        {menuOpen && <MenuList>{menuListItems}</MenuList>}
      </MobileMenu>
      <DesktopMenu>
        <MenuList>{menuListItems}</MenuList>
      </DesktopMenu>
    </>
  )
}
