import React from 'react'
import { NavLink } from 'react-router-dom'
import CollapseContent from '../../../../../components/CollapseContent/CollapseContent'
import styles from './Participants.module.scss'

const PersonList = ({ persons, personIdentifier }) => (
  <ul className={styles.persons}>
    {persons.map((person) => (
      <li className={styles.persons__info} key={person.rolle}>
        <p className={styles.persons__position + ' p--style-bold'}>
          {person.rolle}
        </p>
        {person[personIdentifier] &&
          person[personIdentifier].map((rollePerson) => (
            <p className={styles.persons__name} key={rollePerson.slug}>
              <i className='far fa-user' />
              <NavLink to={`/person/${rollePerson.slug}`}>
                {rollePerson.title}
              </NavLink>
            </p>
          ))}
      </li>
    ))}
  </ul>
)

const Actors = (props) => (
  <article className={styles.actors}>
    <h3>Schauspieler</h3>
    <PersonList {...props} personIdentifier='spieler' />
  </article>
)

const Team = (props) => (
  <article className={styles.team}>
    <h3>Team</h3>
    <PersonList {...props} personIdentifier='person' />
  </article>
)

const Participants = ({ personen }) => {
  if (!personen.besetzung && !personen.weiterePersonen) return <></>

  return (
    <section className={styles.contributors + ' maxwidthcontainer'}>
      <CollapseContent header={<h2>Mitwirkende</h2>} isOpened='desktopOnly'>
        <div className={styles.contributors__table}>
          {personen.besetzung && <Actors persons={personen.besetzung} />}
          {personen.weiterePersonen && (
            <Team persons={personen.weiterePersonen} />
          )}
        </div>
      </CollapseContent>
    </section>
  )
}

export default Participants
