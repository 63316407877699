import React from 'react'

import styles from './SocialLinks.module.scss'

const SocialLinks = ({ children: socialLinks, footerElement = true }) => {
  if (socialLinks.length === 0) return <></>

  const socialStyleHeader = !footerElement && styles['socials--header']

  return (
    <ul className={`${styles.socials} ${socialStyleHeader}`}>
      {socialLinks.map((item) => (
        <li key={item.id}>
          <a
            href={item.socialLinkFields.link.url}
            target={item.socialLinkFields.link.target}
            rel='noreferrer'
          >
            <i
              className={item.socialLinkFields.icon}
              title={item.socialLinkFields.link.title}
            />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default SocialLinks
