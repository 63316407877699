import React from 'react'
import StatisticItem from '../StatisticItem/StatisticItem'
import styles from './StatisticComponent.module.scss'

const StatisticComponent = ({ statisticItems }) => {
  return (
    <section
      className={
        styles['statistic-component'] + ' maxwidthcontainer sectioncontainer'
      }
    >
      {statisticItems.map((item) => (
        <StatisticItem key={item.label} label={item.label}>
          {item.value ? item.value : '?'}
        </StatisticItem>
      ))}
    </section>
  )
}

export default StatisticComponent
