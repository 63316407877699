import React from 'react'
import styles from './ShowsPastTime.module.scss'

const ShowPastTime = React.forwardRef(function MyComponent(props, ref) {
  return (
    <div {...props} ref={ref} className={styles['shows-time']}>
      <span className='p--size-small p--color-highlight p--style-bold'>{`${props.time.getTime()} Uhr`}</span>
      &nbsp;
      <div className={styles['icon-info'] + ' fa fa-info-circle'} />
    </div>
  )
})

export default ShowPastTime
