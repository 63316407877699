import React from 'react'
import GalleryComponent from '../Gallery/GalleryComponent/GalleryComponent'
import DatetimeHelper from '../../helpers/DatetimeHelper'

const NewsGallery = ({ news }) => (
  <GalleryComponent
    galleryItems={news.map((newsItem) => ({
      id: newsItem.id,
      title: newsItem.title,
      subtitle: new DatetimeHelper(newsItem.date).getShortDateString(),
      url: newsItem.featuredImage?.node?.thumbnail,
      content: newsItem.content,
      routeTo: `/neuigkeiten/${newsItem.slug}`,
      readMore: true
    }))}
    types={news.length > 2 ? 'news news-overview' : 'news'}
  />
)

export default NewsGallery
