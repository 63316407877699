import { FETCH_PAGES_OVERVIEW, FETCH_PAGE_INDIVIDUAL } from './types'
import { graphqlUrl } from './api'
import { request } from 'graphql-request'
import { pagesQuery } from './graphqlQueries'

export const fetchPagesOverview = () => async (dispatch) => {
  return await request(graphqlUrl, pagesQuery()).then((response) =>
    dispatch({
      type: FETCH_PAGES_OVERVIEW,
      payload: response.pages.nodes
    })
  )
}

export const fetchPageIndividual = (slug) => async (dispatch) => {
  return await request(graphqlUrl, pagesQuery(slug)).then((response) =>
    dispatch({
      type: FETCH_PAGE_INDIVIDUAL,
      payload: response.pages.nodes[0]
    })
  )
}
