import React from 'react'
import SearchComponent from '../Search/SearchComponent/SearchComponent'
import { ButtonMain } from '../Button/Button'

const highlightToContent = (highlighting, doc) => {
  const getLabel = (key) => {
    switch (key) {
      case 'infos_txt_de':
        return 'Aktuelle Informationen'
      case 'typ_txt_de':
        return 'Kategorie'
      case 'genre_txt_de':
        return 'Genre'
      case 'autor_txt_de':
        return 'Autor'
      case 'inhalt_txt_de':
        return 'Inhalt'
      case 'verlag_txt_de':
        return 'Verlag'
      case 'zusatzinfos_txt_de':
        return 'Zusätzliche Informationen'
      case 'orte_txt_de':
        return 'Orte'
      case 'spieler_txt_de':
        return 'Spieler'
      case 'team_txt_de':
        return 'Team'
      default:
        return 'Suchergebnis'
    }
  }

  const results = []
  for (const [key, value] of Object.entries(highlighting)) {
    const fullValue = value.join(' ')
    const fullValueWithoutHtml = fullValue.replace(/(<([^>]+)>)/gi, '')
    const cutValue = 10
    const cutValueReverse = cutValue * -1

    const ellipsis = {
      pre: doc[key]
        .slice(0, cutValue)
        .startsWith(fullValueWithoutHtml.slice(0, cutValue))
        ? ''
        : '(...) ',
      post: doc[key]
        .slice(cutValueReverse)
        .startsWith(fullValueWithoutHtml.slice(cutValueReverse))
        ? ''
        : ' (...)'
    }

    if (key !== 'titel_txt_de' && key !== 'slug_txt_de')
      results.push({
        label: getLabel(key),
        value: ellipsis.pre + fullValue + ellipsis.post
      })
  }

  if (!results.length) return <p>{doc.inhalt_txt_de}</p>

  return (
    <>
      <p className='p--size-big'>Suchergebnisse (Auszug):</p>
      {results.slice(0, 2).map((entry) => (
        <div key={entry.label}>
          <p className='p--style-bold'>{entry.label}</p>
          <div dangerouslySetInnerHTML={{ __html: entry.value }} />
        </div>
      ))}
    </>
  )
}

const PlaysSearch = ({ searchResult, onLoadMore }) => {
  const searchResultNum = searchResult.response.numFound

  return (
    <div className='search-results'>
      <p className='p--color-grey p--size-small p--align-center'>
        {`${searchResultNum} ${
          searchResultNum === 1 ? 'Ergebnis' : 'Ergebnisse'
        } gefunden`}
      </p>
      <SearchComponent
        searchItems={searchResult.response.docs.map((doc) => ({
          id: doc.id,
          title: doc.titel_txt_de,
          subtitle: doc.jahr_int,
          url: doc.bild_txt_de,
          content: highlightToContent(searchResult.highlighting[doc.id], doc),
          routeTo: `/stuecke/${doc.slug_txt_de}`
        }))}
      />
      {searchResultNum > searchResult.response.docs.length && (
        <ButtonMain centered={true} onClick={() => onLoadMore()}>
          Mehr anzeigen
        </ButtonMain>
      )}
    </div>
  )
}

export default PlaysSearch
