import React, { useEffect } from 'react'

import Heading from '../../../../components/Heading/Heading'
import LoadingCircle from '../../../../components/LoadingCircle/LoadingCircle'
import NewsGallery from '../../../../components/NewsGallery/NewsGallery'

const NewsOverview = ({ posts, onLoadData }) => {
  useEffect(() => {
    onLoadData()
  }, [onLoadData])

  if (posts.length === 0) return <LoadingCircle />

  return (
    <section className='news maxwidthcontainer sectioncontainer'>
      <Heading title='Neuigkeiten' topTitle='Alle' />
      <NewsGallery news={posts} />
    </section>
  )
}

export default NewsOverview
