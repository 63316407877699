import { VerticalTimelineElement } from 'react-vertical-timeline-component'
import PlayStatistics from '../../../../components/PlayStatistics/PlayStatistics'
import { ButtonGhost } from '../../../../components/Button/Button'
import CollapseContent from '../../../../components/CollapseContent/CollapseContent'
import 'react-vertical-timeline-component/style.min.css'
import './TimelineElement.scss'
import { Link } from 'react-router-dom'
import {
  getTheaterTypColor,
  getTheaterTypEmoji
} from '../../../../helpers/TheaterTypHelper'

const TimelineElement = ({ play }) => {
  const color = getTheaterTypColor(play.acf.typ)

  return (
    <VerticalTimelineElement
      contentStyle={{
        color: 'white',
        borderTop: `3px solid ${color}`
      }}
      contentArrowStyle={{
        borderRight: `1rem solid white`
      }}
      date={play.acf.jahr}
      dateClassName='year'
      iconStyle={{
        background: 'white',
        boxShadow: `0 0 0 4px ${color}, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)`
      }}
      icon={<i>{getTheaterTypEmoji(play.acf.typ)}</i>}
      iconClassName='icon'
    >
      <Link to={`/stuecke/${play.slug}`}>
        <h3 className='vertical-timeline-element-title timeline-element__title'>
          {play.title}
        </h3>
      </Link>

      <div className='timeline-element__body'>
        <div className='timeline-element__body--main'>
          <Link to={`/stuecke/${play.slug}`}>
            <img src={play.acf.beitragsbild.url} />
          </Link>
        </div>

        <CollapseContent header={<h3>Daten und Fakten</h3>} isOpened='never'>
          <PlayStatistics play={play} />
        </CollapseContent>

        <ButtonGhost type='link' link={`/stuecke/${play.slug}`} centered={true}>
          Alle Infos zum Stück
        </ButtonGhost>
      </div>
    </VerticalTimelineElement>
  )
}

export default TimelineElement
