import { connect } from 'react-redux'
import Footer from './Footer'
import { fetchMenus } from '../../../actions/menu-actions'
import { fetchSocialLinks } from '../../../actions/social-link-actions'

const mapStateToProps = (state) => {
  return {
    ...state.menus,
    ...state.socialLinks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => {
      dispatch(fetchMenus())
      dispatch(fetchSocialLinks())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
