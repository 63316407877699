import { restUrl } from './api'
const contactFormId = 2060
const contactFormUrl = `${restUrl}contact-form-7/v1/contact-forms/${contactFormId}/feedback`

export const sendContactForm = (formValues) => async () => {
  const formDataValues = new FormData()
  for (const field in formValues) {
    formDataValues.set(field, formValues[field])
  }

  return await fetch(contactFormUrl, {
    method: 'POST',
    body: formDataValues
  })
    .then((response) => response.json())
    .then((json) => json)
}
