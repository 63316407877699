import { gql } from 'graphql-request'

export const postsQuery = (slug = '') => gql`
  {
    posts(first: 100, where: { name: "${slug}", orderby: {field: DATE, order: DESC} } ) {
      nodes {
        id
        title(format: RENDERED)
        slug
        date
        isSticky
        content(format: RENDERED)
        featuredImage {
          node {
            url: sourceUrl
            thumbnail: sourceUrl(size: MEDIUM_LARGE)
          }
        }
      }
    }
  }
`

export const pagesQuery = (slug = '') => gql`
  {
    pages(first: 100, where: { name: "${slug}", orderby: {field: DATE, order: DESC} } ) {
      nodes {
        id
        title(format: RENDERED)
        slug
        date
        content(format: RENDERED)
        parent {
          node {
            id
          }
        }
      }
    }
  }
`

export const playsQueryForUpcoming = () => gql`
  {
    plays(first: 100) {
      nodes {
        slug
        acf: playFields {
          istAktuellesStuck
        }
      }
    }
  }
`

export const playsQuery = (slug = '') => gql`
  {
    plays(first: 100, where: { name: "${slug}" } ) {
      nodes {
        id
        title(format: RENDERED)
        slug
        acf: playFields {
          aktuelleInfos
          auffuhrungen {
            besucheranzahl
            ort {
              ... on Location {
                id
                title(format: RENDERED)
              }
            }
            ticketPreis
            ticketLink {
              url
            }
            zeitpunkt
            zusatzInfo
          }
          aufzeichnung
          aufzeichnungspasswort
          autor
          beitragsbild {
            url: sourceUrl
            thumbnail: sourceUrl(size: MEDIUM_LARGE)
          }
          besucherGesamt
          einladung {
            url: mediaItemUrl
          }
          fotos {
            id,
            url: sourceUrl
            thumbnail: sourceUrl(size: MEDIUM_LARGE)
          }
          genre
          inhalt
          istAktuellesStuck
          jahr
          kauflink
          personen {
            besetzung {
              rolle
              spieler {
                ... on Person {
                  slug,
                  title(format: RENDERED)
                }
              }
            }
            weiterePersonen {
              person {
                ... on Person {
                  id,
                  slug,
                  title(format: RENDERED)
                }
              }
              rolle
            }
          }
          plakat {
            url: mediaItemUrl
          }
          presseberichte {
            id,
            url: sourceUrl
            thumbnail: sourceUrl(size: MEDIUM_LARGE)
          }
          ticketsAb
          typ
          verlag
          videos {
            video
          }
          zusatzinformationen
        }
      }
    }
  }
`

export const personsQuery = (slug, endCursor = '') => gql`
  {
    persons(first: 100, after: "${endCursor}" where: {name: "${slug}"}) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        title(format: RENDERED)
        slug
        acf: personFields {
          aktuellesVorstandsmitglied
          foto {
            url: sourceUrl
            thumbnail: sourceUrl(size: MEDIUM_LARGE)
          }
          funktionen {
            funktion
            von
            bis
          }
          geburtsname
          mitgliedsstatus
          nachname
          vorname
        }
      }
    }
  }
`

export const activitiesQuery = (slug = '') => gql`
  {
    activities(first: 100, where: {name: "${slug}"}) {
      nodes {
        id
        title(format: RENDERED)
        slug
        acf: activityFields {
          name
          zeitraum {
            von
            bis
          }
          beschreibung
          fotos {
            id
            url: sourceUrl
            thumbnail: sourceUrl(size: MEDIUM_LARGE)
          }
        }
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
      }
    }
  }
`

export const socialLinksQuery = () => gql`
  {
    socialLinks(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
      nodes {
        id
        socialLinkFields {
          icon
          link {
            title
            url
            target
          }
        }
      }
    }
  }
`

export const menusQuery = () => gql`
  {
    menus {
      nodes {
        id
        name
        slug
        menuItems {
          nodes {
            label
            path
          }
        }
      }
    }
  }
`
