import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle'
import Heading from '../../../components/Heading/Heading'
import CollapseContent from '../../../components/CollapseContent/CollapseContent'

const AboutPages = ({ pages }) => {
  if (!pages?.length) return <LoadingCircle />

  const about = pages.find((page) => page.slug === 'ueber-uns')
  const subPages = pages
    .filter((page) => page.parent?.node?.id === about.id)
    .sort((a, b) => (a.id > b.id && 1) || -1)

  return (
    <>
      <Heading title={about.title} />
      <section className='smallcontainer sectioncontainer contentcontainer'>
        <div
          className='wordpress-html'
          dangerouslySetInnerHTML={{ __html: about.content }}
        />
        {subPages.map((subPage) => (
          <CollapseContent
            key={subPage.id}
            isOpened='never'
            header={<h3>{subPage.title}</h3>}
          >
            <div
              className='wordpress-html'
              dangerouslySetInnerHTML={{ __html: subPage.content }}
            />
          </CollapseContent>
        ))}
      </section>
    </>
  )
}

export default AboutPages
