import { FC } from 'react'

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { BaseButtonProps } from './types'

const defaultStyling = (centered?: boolean) => css`
  margin: 0;
  overflow: visible;
  text-transform: none;
  appearance: none;
  border: none;
  cursor: pointer;

  text-decoration: none;
  text-align: center;
  line-height: 1.5;

  ${centered && 'margin-block: auto;'}
`

type StyledBaseButtonProps = {
  $centered?: boolean
}

export const StyledButton = styled.button<StyledBaseButtonProps>`
  ${(props) => defaultStyling(props.$centered)}
`

const StyledLink = styled(Link)<StyledBaseButtonProps>`
  ${(props) => defaultStyling(props.$centered)}
`

const StyledInput = styled.input<StyledBaseButtonProps>`
  ${(props) => defaultStyling(props.$centered)}
`

export const BaseButton: FC<BaseButtonProps> = ({
  children,
  onClick,
  btnTypeClass,
  link = '',
  centered = false,
  type = 'button'
}) => {
  switch (type) {
    case 'button':
      return (
        <StyledButton
          onClick={onClick}
          $centered={centered}
          className={btnTypeClass}
        >
          {children}
        </StyledButton>
      )

    case 'link':
      return (
        <StyledLink to={link} $centered={centered} className={btnTypeClass}>
          {children}
        </StyledLink>
      )

    default:
      return (
        <StyledInput
          type={type}
          onClick={onClick}
          $centered={centered}
          value={children?.toString()}
        />
      )
  }
}
