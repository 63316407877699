import React, { useState, useEffect } from 'react'

import PhotoGalleryItem from '../PhotoGalleryItem/PhotoGalleryItem'

import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import lgThumbnail from 'lightgallery/plugins/thumbnail'

import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-zoom.scss'
import 'lightgallery/css/lg-thumbnail.css'

const PhotoGalleryComponent = ({ photoItems }) => {
  const [renderGallery, setRenderGallery] = useState(false)

  useEffect(() => {
    // small hack to force rerender of LG-component,
    // otherwise it doesn't work on same components
    // with different content (e.g. activities individual)
    setTimeout(() => {
      setRenderGallery(true)
    }, 0)
    return () => {
      setRenderGallery(false)
    }
  }, [photoItems])

  return (
    <section>
      {renderGallery && (
        <LightGallery
          plugins={[lgThumbnail, lgZoom]}
          mode='lg-fade'
          licenseKey='gplv3'
        >
          {photoItems.map((item) => {
            return <PhotoGalleryItem key={item.id} item={item} />
          })}
        </LightGallery>
      )}
    </section>
  )
}

export default PhotoGalleryComponent
