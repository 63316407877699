import GalleryComponent from '../../../components/Gallery/GalleryComponent/GalleryComponent'
import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle'

const AboutBoard = ({ persons }) => {
  if (persons?.length === 0) return <LoadingCircle />

  const members = persons.filter(
    (person) => person.acf.aktuellesVorstandsmitglied
  )

  // custom sort order for Vorstand
  const sortOrderFunktionen = [
    'Obfrau',
    'Obmann',
    'Obfrau-Stellvertreter',
    'Obfrau-Stellvertreterin',
    'Obmann-Stellvertreter',
    'Obmann-Stellvertreterin',
    'Kassierin',
    'Kassier',
    'Schriftführerin',
    'Schriftführer',
    'Beirat',
    'Erweiterter Vorstand'
  ]

  return (
    <section className='maxwidthcontainer sectioncontainer'>
      <h2>Vorstand</h2>
      <GalleryComponent
        galleryItems={members
          .sort(
            (a, b) =>
              sortOrderFunktionen.indexOf(a.acf.funktionen[0]?.funktion) -
              sortOrderFunktionen.indexOf(b.acf.funktionen[0]?.funktion)
          )
          .map((member) => {
            return {
              id: member.id,
              title: member.title,
              subtitle: member.acf.funktionen[0]?.funktion,
              url: member.acf.foto?.url,
              routeTo: `/person/${member.slug}`
            }
          })}
      />
    </section>
  )
}

export default AboutBoard
