import React from 'react'
import DatetimeHelper from '../../../../helpers/DatetimeHelper'
import styles from './UpcomingTicket.module.scss'

const UpcomingTicket = ({ auffuhrung, ticketsAb }) => {
  const auffuhrungTime = new Date(auffuhrung.zeitpunkt).getTime()
  const currentDateTime = new Date().getTime()
  const ticketsAbTime = new Date(ticketsAb).getTime()

  const isBeforeAuffuhrung = currentDateTime < auffuhrungTime

  const isTicketAvailable =
    ticketsAbTime < currentDateTime && auffuhrungTime > currentDateTime

  return (
    <div className={styles['upcoming-ticket']}>
      <div className={styles['upcoming-ticket__datetime']}>
        <div className={styles['upcoming-ticket__date']}>
          {new DatetimeHelper(auffuhrung.zeitpunkt).getLongDateString()}
        </div>
        <div className={styles['upcoming-ticket__time']}>
          <p className='p--color-grey p--size-small'>
            {new DatetimeHelper(auffuhrung.zeitpunkt).getTime()}
          </p>
        </div>
      </div>

      {auffuhrung.ticketLink && (
        <>
          {isTicketAvailable ? (
            <a
              href={auffuhrung.ticketLink.url}
              target='_blank'
              rel='noreferrer'
            >
              <div className={styles['upcoming-ticket__link']}>
                <span className='p--color-highlight'>
                  Reservieren
                  <br />
                  ab {auffuhrung.ticketPreis} EUR
                </span>
              </div>
            </a>
          ) : (
            <div
              className={
                styles['upcoming-ticket__link'] +
                ' ' +
                styles['upcoming-ticket__link--disabled'] +
                ' p--size-small'
              }
            >
              <span className='p--color-highlight'>
                {isBeforeAuffuhrung ? (
                  <>
                    Tickets ab
                    <br />
                    {new DatetimeHelper(ticketsAb).getShortDateString()}
                  </>
                ) : (
                  <>
                    Nicht mehr
                    <br />
                    verfügbar
                  </>
                )}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default UpcomingTicket
