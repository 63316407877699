import React from 'react'
import styles from './StatisticItem.module.scss'

const StatisticItem = ({ children, label }) => {
  return (
    <div className={styles['statistic-item']}>
      <h2 className={styles['statistic-item__value'] + ' h2--size-statistic'}>
        {children}
      </h2>
      <p className={styles['statistic-item__label']}>{label}</p>
    </div>
  )
}

export default StatisticItem
