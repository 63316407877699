import DatetimeHelper from '../../../../../../../helpers/DatetimeHelper'
import styles from './ShowsUpcomingItem.module.scss'

const ShowsUpcomingItem = ({ show, ticketsAb }) => {
  const zeitpunkt = new DatetimeHelper(show.zeitpunkt)

  const auffuhrungTime = new Date(show.zeitpunkt).getTime()
  const currentDateTime = new Date().getTime()
  const ticketsAbTime = new Date(ticketsAb).getTime()

  const isBeforeAuffuhrung = currentDateTime < auffuhrungTime

  const isTicketAvailable =
    ticketsAbTime < currentDateTime && auffuhrungTime > currentDateTime

  return (
    <article className={styles['show-upcoming']}>
      <div className={styles['show-upcoming__date']}>
        <p className={styles['show-upcoming__date-weekday']}>
          {zeitpunkt.getDayOfWeek()}
        </p>
        <div
          className={
            styles['show-upcoming__date-monthday'] + ' h2--size-statistic'
          }
        >
          {zeitpunkt.getDayOfMonth()}
        </div>
        <p className={styles['show-upcoming__date-month']}>
          {zeitpunkt.getMonthName()}
        </p>
      </div>
      <div className={styles['show-upcoming__info']}>
        <div className={styles['show-upcoming__info__time']}>
          {zeitpunkt.getTime()} Uhr
        </div>
        <div>{show.ort.title}</div>
        <div>{show.zusatzInfo}</div>
        <div className={styles['show-upcoming__info__price']}>
          € {show.ticketPreis},00
        </div>
      </div>
      <div className={styles['show-upcoming__container']}>
        {isTicketAvailable ? (
          <a
            href={show.ticketLink?.url}
            target='_blank'
            rel='noreferrer'
            className={styles['show-upcoming__container__ticket']}
          >
            <span className='p--color-highlight'>Tickets reservieren</span>
          </a>
        ) : isBeforeAuffuhrung ? (
          <div
            className={
              styles['show-upcoming__container__ticket'] +
              ' ' +
              styles['show-upcoming__container__ticket--disabled']
            }
          >
            <span className='p--color-highlight'>
              Reservierung ab{' '}
              {new DatetimeHelper(ticketsAb).getShortDateString()}
            </span>
          </div>
        ) : (
          <div
            className={
              styles['show-upcoming__container__ticket'] +
              ' ' +
              styles['show-upcoming__container__ticket--disabled']
            }
          >
            <span className='p--color-highlight'>
              Tickets nicht mehr verfügbar
            </span>
          </div>
        )}
      </div>
    </article>
  )
}

export default ShowsUpcomingItem
