import React, { useEffect } from 'react'

import styles from './Footer.module.scss'
import ExternalLink from '../../../components/ExternalLink/ExternalLink'
import { MenuFooter } from '../../../components/Menu/Menu'
import Newsletter from '../../../components/Newsletter/Newsletter'
import SocialLinks from '../../../components/SocialLinks/SocialLinks'

const Location = ({ mail }) => (
  <div className={styles['footer-main__location']}>
    <h3>Kontakt</h3>
    <p className='p--style-bold'>Theatergruppe Bergheim</p>
    <p>Dorfstraße 26b</p>
    <p>5101 Bergheim</p>
    <br />
    <ExternalLink href={`mailto:${mail}`}>{mail}</ExternalLink>
  </div>
)

const Socials = ({ socialLinks }) => (
  <div className={styles['footer-main__socials']}>
    <h3>Folge Uns</h3>
    <SocialLinks>{socialLinks}</SocialLinks>
  </div>
)

const Post = () => (
  <div className={styles['footer-post']}>
    <p className='p--color-white'>
      &copy; Bernhard Schmidhuber, Lucia Schwarzenbacher, Anna Seidl
    </p>
  </div>
)

const Footer = ({ mainMenu, footerMenu, socialLinks, onLoadData }) => {
  useEffect(() => {
    onLoadData()
  }, [onLoadData])

  const mail = 'kontakt@theaterbergheim.at'

  return (
    <footer className='maxwidthcontainer'>
      <div className={styles.footer}>
        <Newsletter styles={styles} />

        <div className={styles['footer-main']}></div>
        <div className={styles['footer-main__content']}>
          <Location mail={mail} />
          <Socials socialLinks={socialLinks} />
        </div>

        <div className={styles['footer-links']}>
          <MenuFooter menuLinks={footerMenu} horizontal={false} />
        </div>

        <div className={styles['footer-navigation']}>
          <MenuFooter menuLinks={mainMenu} />
        </div>

        <Post />
      </div>
    </footer>
  )
}

export default Footer
