import React from 'react'
import { VerticalTimeline } from 'react-vertical-timeline-component'
import TimelineElement from '../TimelineElement/TimelineElement'
import 'react-vertical-timeline-component/style.min.css'

const Timeline = ({ plays }) => (
  <VerticalTimeline>
    {plays.map((play) => (
      <TimelineElement key={play.id} play={play} />
    ))}
  </VerticalTimeline>
)
export default Timeline
