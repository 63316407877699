import ShowsUpcomingItem from './ShowsUpcomingItem/ShowsUpcomingItem'

const ShowsUpcoming = ({ children, ticketsAb }) => {
  return (
    <section className={'smallcontainer sectioncontainer'}>
      <h2>Aufführungen</h2>
      <ul>
        {children.map((show) => (
          <li key={show.zeitpunkt}>
            <ShowsUpcomingItem show={show} ticketsAb={ticketsAb} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ShowsUpcoming
