import { FETCH_ACTIVITIES_INDIVIDUAL, FETCH_ACTIVITIES_OVERVIEW } from './types'
import { graphqlUrl } from './api'
import { request } from 'graphql-request'
import { activitiesQuery } from './graphqlQueries'

export const fetchActivitiesOverview = () => async (dispatch) => {
  return await request(graphqlUrl, activitiesQuery()).then((response) =>
    dispatch({
      type: FETCH_ACTIVITIES_OVERVIEW,
      payload: response.activities.nodes.sort(
        (a, b) => new Date(b.acf.zeitraum.von) - new Date(a.acf.zeitraum.von)
      )
    })
  )
}

export const fetchActivitiesIndividual = (slug) => async (dispatch) => {
  return await request(graphqlUrl, activitiesQuery(slug)).then((response) =>
    dispatch({
      type: FETCH_ACTIVITIES_INDIVIDUAL,
      payload: response.activities.nodes[0]
    })
  )
}
