import React from 'react'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { ButtonMain } from '../Button/Button'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import 'reactjs-popup/dist/index.css'
import PopupButton from '../PopupButton/PopupButton'

const mailchimpSubscribeUrl =
  'https://theaterbergheim.us1.list-manage.com/subscribe/post?u=3eb93ab1e09e103105f627795&amp;id=99186e3318'

const validationSchema = object({
  email: string().email('Ungültige E-Mail-Adresse').required()
})

const CustomForm = ({ styles, status, message, onValidated }) => {
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) =>
      onValidated({
        EMAIL: values.email
      })
  })

  return (
    <form
      className={styles['footer-newsletter__content']}
      onSubmit={formik.handleSubmit}
    >
      <p
        className={
          styles['footer-newsletter__heading'] + ' p--color-white p--style-bold'
        }
      >
        Newsletter abonnieren:
      </p>

      <div className={styles['footer-newsletter__email']}>
        <input
          className='p--size-small p--color-grey'
          type='email'
          name='email'
          placeholder='E-Mail Adresse'
          value={formik.values.email}
          onChange={(evt) => {
            formik.handleChange(evt)
          }}
        />
      </div>

      <PopupButton popupState={{ status: status, message: message }}>
        <ButtonMain type='submit'>Abonnieren</ButtonMain>
      </PopupButton>
    </form>
  )
}

const Newsletter = ({ styles }) => {
  return (
    <div className={styles['footer-newsletter']}>
      <MailchimpSubscribe
        url={mailchimpSubscribeUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            styles={styles}
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  )
}

export default Newsletter
