import CollapseContent from '../../../../../components/CollapseContent/CollapseContent'
import PhotoGalleryComponent from '../../../../../components/PhotoGallery/PhotoGalleryComponent/PhotoGalleryComponent'

const PressArticles = ({ images }) => {
  return images ? (
    <section className='gallery maxwidthcontainer'>
      <CollapseContent header={<h2>Presseberichte</h2>} isOpened='desktopOnly'>
        <PhotoGalleryComponent photoItems={images} />
      </CollapseContent>
    </section>
  ) : (
    <></>
  )
}

export default PressArticles
