import React from 'react'
import styles from './Videos.module.scss'
import EmbedVideo from './EmbedVideo'

const Videos = ({ videos }) => {
  return videos ? (
    <section className={styles.videos + ' maxwidthcontainer sectioncontainer'}>
      {videos.length === 1 ? <h2>Video</h2> : <h2>Videos</h2>}
      {videos.map((video) => (
        <article key={video.video}>
          <EmbedVideo srcUrl={video.video} />
        </article>
      ))}
    </section>
  ) : (
    <></>
  )
}

export default Videos
