import PlaysGallery from '../../../../../components/PlaysGallery/PlaysGallery'

const PersonPlays = ({ person, plays }) => {
  const personPlays = plays
    .map((play) => {
      const mitwirkende = play.acf.personen

      play['person-details'] = {
        person: person
      }

      const getOccurances = (propNameMulti, propNameSingle) => {
        if (mitwirkende[propNameMulti]) {
          play['person-details'][propNameMulti] = mitwirkende[
            propNameMulti
          ].reduce((accumulator, currentValue) => {
            currentValue[propNameSingle]
              .filter((p) => p.slug === person.slug)
              .forEach(() => accumulator.push(currentValue.rolle))
            return accumulator
          }, [])
        }
      }

      getOccurances('besetzung', 'spieler')
      getOccurances('weiterePersonen', 'person')

      return play
    })
    .filter(
      (play) =>
        play['person-details'].besetzung?.length ||
        play['person-details'].weiterePersonen?.length
    )

  if (personPlays.length === 0) return <></>

  return (
    <section className='maxwidthcontainer sectioncontainer'>
      <h2>Mitgewirkt bei folgenden Stücken:</h2>
      <PlaysGallery plays={personPlays} />
    </section>
  )
}

export default PersonPlays
