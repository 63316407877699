import { FETCH_PERSONS_OVERVIEW, FETCH_PERSON_INDIVIDUAL } from './types'
import { graphqlUrl } from './api'
import { request } from 'graphql-request'
import { personsQuery } from './graphqlQueries'

export const fetchPersonsOverview = () => async (dispatch) => {
  let hasNextPage = true
  let endCursor = null

  const results = []

  while (hasNextPage) {
    const response = await request(graphqlUrl, personsQuery('', endCursor))
    const pageInfo = response.persons.pageInfo
    hasNextPage = pageInfo.hasNextPage
    endCursor = pageInfo.endCursor
    results.push(...response.persons.nodes)
  }

  dispatch({
    type: FETCH_PERSONS_OVERVIEW,
    payload: results
  })
}

export const fetchPersonIndividual = (slug) => async (dispatch) => {
  return await request(graphqlUrl, personsQuery(slug)).then((response) =>
    dispatch({
      type: FETCH_PERSON_INDIVIDUAL,
      payload: response.persons.nodes[0]
    })
  )
}
