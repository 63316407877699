import React from 'react'
import PhotoGalleryComponent from '../PhotoGallery/PhotoGalleryComponent/PhotoGalleryComponent'

const SingleContent = ({ item }) => {
  return (
    <section className='contentcontainer'>
      <div className='smallcontainer sectioncontainer'>
        <div
          className='wordpress-html'
          dangerouslySetInnerHTML={{
            __html: item.content ? item.content : item.acf.beschreibung
          }}
        />
      </div>

      {item.acf?.fotos && <PhotoGalleryComponent photoItems={item.acf.fotos} />}
    </section>
  )
}

export default SingleContent
