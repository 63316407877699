import React from 'react'
import styles from './GalleryComponent.module.scss'
import GalleryItem from '../GalleryItem/GalleryItem'

const GalleryComponent = ({ galleryItems, showTitles, types }) => (
  <section className={styles.gallery}>
    {galleryItems.map((item) => {
      return (
        <GalleryItem
          key={item.id}
          item={item}
          showTitles={showTitles}
          types={types}
        />
      )
    })}
  </section>
)

export default GalleryComponent
