import { connect } from 'react-redux'

import Header from './Header'
import { fetchMenus } from '../../../actions/menu-actions'
import { fetchSocialLinks } from '../../../actions/social-link-actions'

const mapStateToProps = (state) => {
  return {
    mainMenu: state.menus.mainMenu,
    ...state.socialLinks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => {
      dispatch(fetchMenus())
      dispatch(fetchSocialLinks())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
