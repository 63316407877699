import DatetimeHelper from '../../../../../helpers/DatetimeHelper'
import styles from './PersonInfo.module.scss'

const PersonInfo = ({ person }) => {
  return (
    <section
      className={styles['player-info'] + ' maxwidthcontainer sectioncontainer'}
    >
      {person.acf.foto ? (
        <img
          src={person.acf.foto?.url}
          className={styles['player-info__img']}
          alt={person.title.rendered}
        />
      ) : (
        <div className={styles['player-info__img']}>
          <div
            className={styles['player-info__img-fallback'] + ' fas fa-user'}
          ></div>
        </div>
      )}

      <div className={styles['player-info__data']}>
        <div className={styles['player-info__name']}>
          <h1 className='h1--style-light'>{person.acf.vorname}</h1>

          <h1 className='h1--style-bold'>{person.acf.nachname}</h1>

          {person.acf.geburtsname && <p>(geb. {person.acf.geburtsname})</p>}
        </div>

        <div className={styles['player-info__status']}>
          <p>{person.acf.mitgliedsstatus} der Theatergruppe Bergheim</p>

          {person.acf.aktuellesVorstandsmitglied && (
            <p>Aktuelles Vorstandsmitglied</p>
          )}
        </div>

        {person.acf.funktionen && (
          <div className={styles['player-info__functions']}>
            <PersonFunctions functions={person.acf.funktionen} />
          </div>
        )}
      </div>
    </section>
  )
}

const PersonFunctions = ({ functions }) => (
  <>
    {functions.map((functionsItem) => (
      <PersonFunctionsItem
        key={functionsItem.funktion}
        functionsItem={functionsItem}
      />
    ))}
  </>
)

const PersonFunctionsItem = ({ functionsItem }) => {
  let functionsContent

  const dateFrom = new DatetimeHelper(functionsItem.von).getShortDateString()
  const dateTo = new DatetimeHelper(functionsItem.bis).getShortDateString()

  functionsItem.von &&
    functionsItem.bis &&
    (functionsContent = `von ${dateFrom} bis ${dateTo}`)

  functionsItem.von &&
    !functionsItem.bis &&
    (functionsContent = `seit ${dateFrom}`)

  !functionsItem.von &&
    functionsItem.bis &&
    (functionsContent = `bis ${dateTo}`)

  return (
    <>
      <p className={styles.functions__timeframe}>{functionsContent}</p>
      <p className={styles.functions__function}>{functionsItem.funktion}</p>
    </>
  )
}

export default PersonInfo
