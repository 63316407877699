import StatisticComponent from '../../../components/Statistic/StatisticComponent/StatisticComponent'
import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle'

const AboutStatistics = ({ plays, persons }) => {
  if (!plays?.length || !persons?.length) return <LoadingCircle />

  const members = persons.filter(
    (person) =>
      person.acf.mitgliedsstatus === 'Mitglied' ||
      person.acf.mitgliedsstatus === 'Kindertheater-Mitglied'
  )

  const showsTotal = plays.reduce(
    (accumulator, currentValue) =>
      (accumulator += currentValue.acf.auffuhrungen.length),
    0
  )

  return (
    <StatisticComponent
      statisticItems={[
        { label: 'Mitglieder', value: members.length },
        { label: 'Gründungsjahr', value: '1976' },
        { label: 'Stücke', value: plays.length },
        { label: 'Aufführungen', value: showsTotal }
      ]}
    />
  )
}

export default AboutStatistics
