import {
  FETCH_PLAYS_OVERVIEW,
  FETCH_PLAYS_SOLR_RESULT,
  FETCH_PLAYS_UPCOMING,
  FETCH_PLAY_INDIVIDUAL
} from './types'
import { solrAppUrl, graphqlUrl } from './api'
import { request } from 'graphql-request'
import { playsQuery, playsQueryForUpcoming } from './graphqlQueries'

export const fetchPlaysUpcoming = () => async (dispatch) => {
  return await request(graphqlUrl, playsQueryForUpcoming()).then((response) => {
    const promises = response.plays.nodes
      .filter((play) => play.acf.istAktuellesStuck)
      .map((currentPlay) => {
        return request(graphqlUrl, playsQuery(currentPlay.slug))
      })

    Promise.all(promises).then((responses) => {
      dispatch({
        type: FETCH_PLAYS_UPCOMING,
        payload: responses.map(
          (singleResponse) => singleResponse.plays.nodes[0]
        )
      })
    })
  })
}

export const fetchPlaysOverview = () => async (dispatch) => {
  return await request(graphqlUrl, playsQuery()).then((response) =>
    dispatch({
      type: FETCH_PLAYS_OVERVIEW,
      payload: response.plays.nodes.sort((a, b) => b.acf.jahr - a.acf.jahr)
    })
  )
}

export const fetchPlayIndividual = (slug) => async (dispatch) => {
  return await request(graphqlUrl, playsQuery(slug)).then((response) =>
    dispatch({
      type: FETCH_PLAY_INDIVIDUAL,
      payload: response.plays.nodes[0]
    })
  )
}

export const fetchSearchResults =
  (searchOptions, spellingCorrection) => async (dispatch) => {
    return await fetch(`${solrAppUrl}query`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json'
      }),
      body: JSON.stringify({
        query: searchOptions.formValues,
        start: searchOptions.offset,
        rows: searchOptions.limit
      })
    })
      .then((response) => response.json())
      .then((json) =>
        dispatch({
          type: FETCH_PLAYS_SOLR_RESULT,
          payload: json,
          appendData: searchOptions.offset > 0,
          spellingCorrection
        })
      )
  }
