import { connect } from 'react-redux'
import { fetchPageIndividual } from '../../../actions/page-actions'
import Privacy from './Privacy'

const mapStateToProps = (state) => {
  return { ...state.pages }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(fetchPageIndividual('datenschutzerklaerung'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy)
