import React from 'react'

import { Route, Routes } from 'react-router-dom'

import { usePageTitle } from 'hooks'

import ScrollTopButton from '../../../components/ScrollTopButton/ScrollTopButton'
import About from '../../sites/about'
import ActivitiesIndividual from '../../sites/about/activities/individual'
import Contact from '../../sites/contact'
import Home from '../../sites/home'
import Imprint from '../../sites/imprint'
import NewsIndividual from '../../sites/news/individual'
import NewsOverview from '../../sites/news/overview'
import PersonIndividual from '../../sites/persons/individual'
import PlayIndividual from '../../sites/plays/individual'
import PlaysOverview from '../../sites/plays/overview'
import PlayPhotos from '../../sites/plays/photos'
import Privacy from '../../sites/privacy'
import Search from '../../sites/search'

const Page = ({ Component, title }) => {
  usePageTitle(title)
  return <Component />
}

const Main = () => {
  const routes = [
    {
      Component: PlayPhotos,
      title: 'Stück - Fotos',
      path: '/stuecke/:slug/fotos'
    },
    { Component: PlayIndividual, title: 'Stück', path: '/stuecke/:slug' },
    { Component: PlaysOverview, title: 'Stücke', path: '/stuecke' },
    { Component: PersonIndividual, title: 'Person', path: '/person/:slug' },
    {
      Component: NewsIndividual,
      title: 'Neuigkeit',
      path: '/neuigkeiten/:slug'
    },
    {
      Component: NewsOverview,
      title: 'Neuigkeiten',
      path: '/neuigkeiten'
    },
    { Component: About, title: 'Über uns', path: '/ueber-uns' },
    {
      Component: ActivitiesIndividual,
      title: 'Aktivität',
      path: '/aktivitaten/:slug'
    },
    { Component: Search, title: 'Suchen', path: '/suchen' },
    { Component: Contact, title: 'Kontakt', path: '/kontakt' },
    { Component: Imprint, title: 'Impressum', path: '/impressum' },
    { Component: Privacy, title: 'Datenschutzerklärung', path: '/datenschutz' },
    { Component: Home, path: '/' }
  ]

  return (
    <main>
      <Routes>
        <Route
          path='/wp-admin'
          render={() => {
            if (
              location.hostname.includes('localhost') ||
              location.hostname.includes('127.0.0.1')
            )
              window.open('http://theaterbergheim.localhost/wp-admin', '_blank')
          }}
        />
        {routes.map(({ Component, title, path }) => (
          <Route
            key={path}
            exact
            path={path}
            element={<Page Component={Component} title={title} />}
          ></Route>
        ))}
      </Routes>
      <ScrollTopButton />
    </main>
  )
}

export default Main
