import { connect } from 'react-redux'
import PlayPhotos from './PlayPhotos'
import { fetchPlayIndividual } from '../../../../actions/plays-actions'

const mapStateToProps = (state) => {
  return state.plays
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (slug) => dispatch(fetchPlayIndividual(slug))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayPhotos)
