import React, { useEffect } from 'react'

import Heading from '../../../components/Heading/Heading'
import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle'
import PlaysSearch from '../../../components/PlaysSearch/PlaysSearch'
import SearchFilter from '../../../components/SearchFilter/SearchFilter'

const Search = ({
  plays,
  searchResult,
  onLoadPlays,
  onSearch,
  onLoadMore,
  spellingCorrection
}) => {
  useEffect(() => {
    onLoadPlays()
  }, [onLoadPlays, searchResult])

  if (plays.length === 0) return <LoadingCircle />

  const hasSolrResult = !!(
    searchResult &&
    Object.keys(searchResult).length &&
    searchResult.constructor === Object
  )

  const yearSpan = {
    from: plays.reduce(
      (prev, curr) => (prev && prev.acf.jahr < curr.acf.jahr ? prev : curr),
      null
    ).acf.jahr,
    to: plays.reduce(
      (prev, curr) => (prev && prev.acf.jahr > curr.acf.jahr ? prev : curr),
      null
    ).acf.jahr
  }

  const category = ['Alle', 'Kinder', 'Erwachsene']

  const sortingOptions = ['Relevanz', 'Neuere zuerst', 'Ältere zuerst']

  if (
    hasSolrResult &&
    !searchResult.response.numFound &&
    !searchResult.spellcheck.correctlySpelled &&
    searchResult.spellcheck.suggestions.length &&
    searchResult.spellcheck.collations.length
  ) {
    const correction = searchResult.spellcheck.collations
      .filter((collation) => collation?.collationQuery)[0]
      .collationQuery.slice(7)

    onSearch({ 'search-text': correction }, correction)
  }

  return (
    <>
      <section className='maxwidthcontainer sectioncontainer'>
        <Heading title='Suchen' topTitle='Stücke' />

        <SearchFilter
          onReloadData={onSearch}
          yearSpan={yearSpan}
          category={category}
          sortingOptions={sortingOptions}
        />

        {spellingCorrection?.wrong && searchResult.response.numFound > 0 && (
          <p className='p--align-center'>
            {'Suche nach '}
            <span className='p--style-bold'>{spellingCorrection.wrong}</span>
            {' liefert keine Ergebnisse. Stattdessen Suche nach '}
            <span className='p--style-bold'>{spellingCorrection.right}</span>.
          </p>
        )}

        {hasSolrResult && (
          <PlaysSearch searchResult={searchResult} onLoadMore={onLoadMore} />
        )}
      </section>
    </>
  )
}

export default Search
