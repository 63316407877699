import React, { useEffect } from 'react'
import Timeline from './Timeline/Timeline'
import NewsGallery from '../../../components/NewsGallery/NewsGallery'
import UpcomingPlay from './UpcomingPlay/UpcomingPlay'
import Heading from '../../../components/Heading/Heading'
import { ButtonMain } from '../../../components/Button/Button'
import { HomeHero } from '../../../components/Hero/Hero'
import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle'

const Home = ({ plays, upcomingPlays, posts, onLoadData }) => {
  useEffect(() => onLoadData(), [onLoadData])

  const pastPlays = plays.filter((play) => !play.acf.istAktuellesStuck)
  const currentNews = posts.filter((post) => post.isSticky)

  const isLoading =
    upcomingPlays?.length === 0 &&
    currentNews.length === 0 &&
    plays.length === 0

  return (
    <>
      <HomeHero
        title='Willkommen bei der Theatergruppe Bergheim'
        isLoading={isLoading}
      >
        {upcomingPlays && upcomingPlays.length > 0 && (
          <section className='upcoming-plays sectioncontainer'>
            {upcomingPlays.map((currentPlay) => (
              <UpcomingPlay key={currentPlay.id} play={currentPlay} />
            ))}
          </section>
        )}
      </HomeHero>

      {currentNews.length > 0 && (
        <section className='news maxwidthcontainer sectioncontainer'>
          <NewsGallery news={currentNews} />
          <ButtonMain
            onClick={() => (window.location.href = '/neuigkeiten')}
            centered={true}
          >
            Alle Neuigkeiten
          </ButtonMain>
        </section>
      )}

      {pastPlays.length > 0 ? (
        <section className='timeline sectioncontainer'>
          <Heading title='Bisherige Stücke' topTitle='Eine kleine Zeitreise' />
          <Timeline plays={pastPlays} />
        </section>
      ) : (
        <LoadingCircle />
      )}
    </>
  )
}

export default Home
