import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Heading from '../../../components/Heading/Heading'
import TextInput from '../../../components/Input/TextInput'
import EmailInput from '../../../components/Input/EmailInput'
import TextareaInput from '../../../components/Input/TextareaInput'
import { ButtonMain } from '../../../components/Button/Button'
import styles from './Contact.module.scss'
import LocationMap from '../../../components/LocationMap/LocationMap'
import yupLocaleDe from './yup-locale-de'
import PopupButton from '../../../components/PopupButton/PopupButton'

yup.setLocale(yupLocaleDe)

const validationSchema = yup.object({
  'your-name': yup.string().required(),
  'your-email': yup.string().email().required(),
  'your-subject': yup.string().required(),
  'your-message': yup.string().required()
})

const Contact = ({ onSubmitData }) => {
  const [popupState, setPopupState] = useState({})
  const responseElement = document.querySelector('.feedback')

  const formik = useFormik({
    initialValues: {
      'your-name': '',
      'your-email': '',
      'your-subject': '',
      'your-message': ''
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) =>
      onSubmitData(values)
        .then((response) => {
          const isSuccess = response.status === 'mail_sent'

          setPopupState({
            status: isSuccess ? 'success' : 'error',
            message: response.message
          })

          isSuccess && formik.resetForm()
        })
        .catch((error) => {
          responseElement.innerHTML = error
        })
  })

  return (
    <>
      <section className='smallcontainer'>
        <Heading title='Kontakt' />
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.form__content}>
            <div className={styles.form__name}>
              <TextInput
                label='Name*'
                onChange={formik.handleChange}
                value={formik.values['your-name']}
                name='your-name'
                placeholder='Vor- und Nachname'
              />
              {formik.errors['your-name'] && (
                <p
                  className={
                    styles.form__errors + ' p--color-highlight p--size-small'
                  }
                >
                  {formik.errors['your-name']}
                </p>
              )}
            </div>

            <div className={styles.form__email}>
              <EmailInput
                label='E-Mail*'
                onChange={formik.handleChange}
                value={formik.values['your-email']}
                name='your-email'
                placeholder='E-Mail Adresse'
              />
              {formik.errors['your-email'] && (
                <p
                  className={
                    styles.form__errors + ' p--color-highlight p--size-small'
                  }
                >
                  {formik.errors['your-email']}
                </p>
              )}
            </div>

            <div className={styles.form__subject}>
              <TextInput
                label='Betreff'
                onChange={formik.handleChange}
                value={formik.values['your-subject']}
                name='your-subject'
                placeholder='Geben Sie Ihren Betreff ein ...'
              />
              {formik.errors['your-subject'] && (
                <p
                  className={
                    styles.form__errors + ' p--color-highlight p--size-small'
                  }
                >
                  {formik.errors['your-subject']}
                </p>
              )}
            </div>

            <div className={styles.form__message}>
              <TextareaInput
                label='Nachricht'
                onChange={formik.handleChange}
                value={formik.values['your-message']}
                name='your-message'
                placeholder='Geben Sie Ihre Nachricht ein ...'
              />
              {formik.errors['your-message'] && (
                <p
                  className={
                    styles.form__errors + ' p--color-highlight p--size-small'
                  }
                >
                  {formik.errors['your-message']}
                </p>
              )}
            </div>
          </div>

          <PopupButton popupState={popupState}>
            <ButtonMain
              className={styles.form__button}
              type='submit'
              centered={true}
            >
              Absenden
            </ButtonMain>
          </PopupButton>
        </form>
      </section>
      <LocationMap />
    </>
  )
}

export default Contact
