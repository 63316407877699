import { FETCH_SOCIAL_LINKS } from './types'
import { graphqlUrl } from './api'
import { request } from 'graphql-request'
import { socialLinksQuery } from './graphqlQueries'

export const fetchSocialLinks = () => async (dispatch) => {
  return await request(graphqlUrl, socialLinksQuery()).then((response) =>
    dispatch({
      type: FETCH_SOCIAL_LINKS,
      payload: response.socialLinks.nodes
    })
  )
}
