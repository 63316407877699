import styled from 'styled-components'

import { StyledButton } from './BaseButton'

const StyledIconButton = styled(StyledButton)`
  display: block;
  background-color: transparent;
`

const StyledIcon = styled.div`
  font-size: 2em;
  color: ${({ theme }) => theme.colors.highlight};
`

type IconButtonProps = {
  onClick: () => void
  iconClass: string
}

export const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  iconClass
}) => (
  <StyledIconButton onClick={onClick}>
    <StyledIcon className={iconClass} />
  </StyledIconButton>
)
