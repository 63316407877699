import React, { useEffect, useState } from 'react'
import styles from './VideoRecordings.module.scss'
import EmbedVideo from './EmbedVideo'
import TextInput from '../../../../../components/Input/TextInput'
import classNames from 'classnames'
import { ButtonMain } from '../../../../../components/Button/Button'

const VideoRecording = ({ password, link, payLink }) => {
  const [userPassword, setUserPassword] = useState('')
  const [passwordCorrect, setPasswordCorrect] = useState(false)

  useEffect(() => {
    if (userPassword === '') return

    setPasswordCorrect(
      userPassword === process.env.REACT_APP_FILM_MASTER_PASSWORD ||
        userPassword === password
    )
  }, [userPassword])

  return (
    <section
      className={classNames(
        styles['video-recordings'],
        'maxwidthcontainer',
        'sectioncontainer'
      )}
    >
      <h2>Gefilmte Vorstellung</h2>
      <div className={styles['video-recordings__content']}>
        {passwordCorrect ? (
          <EmbedVideo srcUrl={link} />
        ) : (
          <div className={styles['video-recordings__columns']}>
            {payLink && (
              <div className={styles['video-recordings__pay']}>
                <p>
                  Eine Vorstellung des Stückes wurde gefilmt, die Aufzeichnung
                  davon kann unter dem folgenden Link käuflich erworben werden:
                </p>
                <div className={styles['video-recordings__paylink']}>
                  <a href={payLink} target='_blank' rel='noreferrer'>
                    <ButtonMain>Gefilmte Vorstellung kaufen</ButtonMain>
                  </a>
                </div>
                <p>Vielen Dank für deine Unterstützung!</p>
              </div>
            )}
            <div className={styles['video-recordings__password']}>
              <p>
                Solltest du ein Passwort besitzen, kannst du die
                Film-Aufzeichnung direkt hier auf der Website freischalten.
              </p>
              <TextInput
                value={userPassword}
                placeholder='Passwort hier eingeben'
                label='Direkte Freischaltung durch Passwort:'
                onChange={(e) => {
                  setUserPassword(e.target.value)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default VideoRecording
