import { combineReducers } from 'redux'
import { postsReducer } from './posts-reducer'
import { personsReducer } from './persons-reducer'
import { playsReducer } from './plays-reducer'
import { menusReducer } from './menus-reducer'
import { socialLinksReducer } from './social-links-reducer'
import { pagesReducer } from './pages-reducer'
import { activitiesReducer } from './activities-reducer'

const rootReducer = combineReducers({
  socialLinks: socialLinksReducer,
  menus: menusReducer,
  plays: playsReducer,
  persons: personsReducer,
  posts: postsReducer,
  pages: pagesReducer,
  activities: activitiesReducer
})

export default rootReducer
