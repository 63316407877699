import ShowsPastItem from './ShowsPastItem/ShowsPastItem'
import styles from './ShowsPast.module.scss'

const ShowsPast = ({ children }) => {
  const groupedByDays = new Map()
  children.forEach((show) => {
    const date = show.zeitpunkt.split(' ')[0]
    if (!groupedByDays.get(date)) groupedByDays.set(date, [])
    groupedByDays.get(date).push(show)
  })

  return (
    <section className={styles['shows-past'] + ' sectioncontainer'}>
      <div className='maxwidthcontainer'>
        <h2>Aufführungen</h2>
      </div>
      <ul className={styles['shows-past__performances'] + ' maxwidthcontainer'}>
        <ShowsPastItem
          days={groupedByDays}
          multipleRows={groupedByDays.size > 7}
          width={100 / Math.ceil(groupedByDays.size / 2)}
        />
      </ul>
    </section>
  )
}

export default ShowsPast
