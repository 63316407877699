import { connect } from 'react-redux'
import {
  fetchActivitiesIndividual,
  fetchActivitiesOverview
} from '../../../../../actions/activities-actions'
import ActivitiesIndividual from './ActivitiesIndividual'

const mapStateToProps = (state) => {
  return state.activities
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (slug) => {
      dispatch(fetchActivitiesIndividual(slug))
      dispatch(fetchActivitiesOverview())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesIndividual)
