import { FETCH_MENUS } from './types'
import { graphqlUrl } from './api'
import { request } from 'graphql-request'
import { menusQuery } from './graphqlQueries'

export const fetchMenus = () => async (dispatch) => {
  return await request(graphqlUrl, menusQuery()).then((response) =>
    dispatch({
      type: FETCH_MENUS,
      payload: response.menus.nodes
    })
  )
}
