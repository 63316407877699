import React, { useEffect } from 'react'

import AboutBoard from './AboutBoard'
import AboutPages from './AboutPages'
import AboutStatistics from './AboutStatistics'
import ActivitiesOverview from './activities/overview/ActivitiesOverview'
import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle'
import LocationMap from '../../../components/LocationMap/LocationMap'

const About = ({ pages, persons, plays, activities, onLoadData }) => {
  useEffect(() => {
    onLoadData()
  }, [onLoadData])

  if (
    pages?.length === 0 &&
    persons?.length === 0 &&
    plays?.length === 0 &&
    activities?.length === 0
  )
    return <LoadingCircle />

  return (
    <div className='about'>
      <AboutPages pages={pages} />
      <AboutStatistics persons={persons} plays={plays} />
      <AboutBoard persons={persons} />
      <ActivitiesOverview activities={activities} />
      <LocationMap />
    </div>
  )
}

export default About
