import { FETCH_POST_INDIVIDUAL, FETCH_POSTS_OVERVIEW } from '../actions/types'

const initialState = {
  posts: [],
  post: {}
}

export const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS_OVERVIEW:
      return {
        ...state,
        posts: action.payload
      }
    case FETCH_POST_INDIVIDUAL:
      return {
        ...state,
        post: action.payload
      }
    default:
      return state
  }
}
