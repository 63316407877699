import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'

import { usePageTitle } from 'hooks'

import { ButtonMain } from '../../../../components/Button/Button'
import Hero from '../../../../components/Hero/Hero'
import LoadingCircle from '../../../../components/LoadingCircle/LoadingCircle'
import NewsGallery from '../../../../components/NewsGallery/NewsGallery'
import SingleContent from '../../../../components/SingleContent/SingleContent'
import DatetimeHelper from '../../../../helpers/DatetimeHelper'

const NewsIndividual = ({ post: paramPost, posts, onLoadData }) => {
  const { slug } = useParams()

  const [post, setPost] = useState(paramPost)

  usePageTitle(post.title)

  useEffect(() => {
    setPost(paramPost)
  }, [paramPost])

  useEffect(() => {
    // load news if necessary
    if (post?.slug !== slug) {
      // search for news in all loaded news
      const foundPost = posts.find((existingPost) => existingPost.slug === slug)
      if (foundPost) {
        setPost(foundPost)
      } else {
        // if news is not found, load individual news from backend
        onLoadData(slug)
      }
    }
  }, [onLoadData, post?.slug, posts, slug])

  if (!post?.title) return <LoadingCircle />

  return (
    <div>
      <Hero
        imageSrc={post.featuredImage?.node?.url}
        title={post.title}
        subtitle={`vom ${new DatetimeHelper(post.date).getShortDateString()}`}
      />

      <SingleContent item={post} />

      {posts.length > 0 && (
        <section className='sectioncontainer'>
          <div className='smallcontainer'>
            <h2>Weitere Neuigkeiten</h2>
          </div>
          <NewsGallery
            news={posts
              .filter((newsListItem) => newsListItem.id !== post.id)
              .slice(0, 2)}
          />
          <ButtonMain type='link' link='/neuigkeiten' centered={true}>
            Alle Neuigkeiten
          </ButtonMain>
        </section>
      )}
    </div>
  )
}

export default NewsIndividual
