import React from 'react'

import { NavLink } from 'react-router-dom'
import { styled, css } from 'styled-components'

import styles from './Menu.module.scss'

const StyledNavLink = styled(NavLink)`
  ${(props) =>
    props.$isHeader &&
    css`
      &.active,
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightgray};

        div {
          color: ${({ theme }) => theme.colors.highlight};
        }
      }
    `}
`

const Menu = ({ menuLinks, isHeader, horizontal = true }) => {
  const verticalStyle = !horizontal && styles['menu--flow-vertical']

  return (
    <nav>
      <ul id='menu' className={`${styles.menu} ${verticalStyle}`}>
        {menuLinks.length !== 0 &&
          menuLinks.map((item) => {
            return (
              <li key={item.label}>
                <StyledNavLink
                  to={item.path}
                  className={isHeader && 'a--style-bold'}
                  $isHeader={isHeader}
                >
                  <div>{item.label}</div>
                </StyledNavLink>
              </li>
            )
          })}
      </ul>
    </nav>
  )
}

export const MenuHeader = (props) => <Menu {...props} isHeader />

export const MenuFooter = (props) => <Menu {...props} />
