import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import styles from './LoadingCircle.module.scss'

const LoadingCircle = () => {
  return (
    <div className={styles.loading}>
      <TailSpin color='#ee964b' />
    </div>
  )
}

export default LoadingCircle
