import React from 'react'
import { ButtonMedia } from '../Button/Button'
import styles from './PlayContent.module.scss'

const PlayContent = ({ play }) => {
  return (
    <section
      className={styles['play-content'] + ' smallcontainer sectioncontainer'}
    >
      <div className={styles['play-content__description']}>
        <h2 className={styles['play-content__title']}>Inhalt</h2>
        <p className={styles['play-content__content']}>{play.acf.inhalt}</p>
        {play.acf.verlag && (
          <p
            className={
              styles['play-content__publisher'] + ' p--color-grey p--size-small'
            }
          >{`Verlag: ${play.acf.verlag}`}</p>
        )}
        {play.acf.zusatzinformationen && (
          <>
            <h3 className={styles['play-content__subtitle']}>Zusatzinfos</h3>
            <p className={styles['play-content__content-plus']}>
              {play.acf.zusatzinformationen}
            </p>
          </>
        )}
      </div>
      {(play.acf.einladung || play.acf.plakat) && (
        <div className={styles['play-content__downloads']}>
          {play.acf.einladung && (
            <ButtonMedia
              onClick={() => window.open(play.acf.einladung.url, '_blank')}
              iconClass='fa fa-file-alt'
              label='Einladung'
            />
          )}
          {play.acf.plakat && (
            <ButtonMedia
              onClick={() => window.open(play.acf.plakat.url, '_blank')}
              iconClass='fa fa-file-alt'
              label='Plakat'
            ></ButtonMedia>
          )}
        </div>
      )}
    </section>
  )
}

export default PlayContent
