import classNames from 'classnames'
import React from 'react'
import styles from './Heading.module.scss'

const Heading = ({ title, topTitle, subTitle }) => {
  return (
    <div className={styles.heading}>
      <h3
        className={classNames(
          'h3--style-bold',
          'h3--color-highlight',
          'h3--transform-uppercase'
        )}
      >
        {topTitle}
      </h3>
      <h1 className={classNames('h1--style-bold', 'h1--transform-uppercase')}>
        {title}
      </h1>
      <hr />
      {subTitle && <p>{subTitle}</p>}
    </div>
  )
}

export default Heading
