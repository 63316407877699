import React from 'react'
import Heading from '../Heading/Heading'
import LoadingCircle from '../LoadingCircle/LoadingCircle'

const StaticPage = ({ page }) => {
  if (!Object.keys(page).length) return <LoadingCircle />

  return (
    <>
      <Heading title={page.title} />

      <section className='smallcontainer sectioncontainer contentcontainer'>
        <div
          className='wordpress-html'
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </section>
    </>
  )
}

export default StaticPage
