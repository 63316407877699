import { connect } from 'react-redux'
import { fetchPagesOverview } from '../../../actions/page-actions'
import { fetchPersonsOverview } from '../../../actions/persons-actions'
import { fetchPlaysOverview } from '../../../actions/plays-actions'
import { fetchActivitiesOverview } from '../../../actions/activities-actions'
import About from './About'

const mapStateToProps = (state) => {
  return {
    ...state.pages,
    ...state.persons,
    ...state.plays,
    ...state.activities
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => {
      dispatch(fetchPagesOverview())
      dispatch(fetchPersonsOverview())
      dispatch(fetchPlaysOverview())
      dispatch(fetchActivitiesOverview())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
