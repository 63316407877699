import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'

import { usePageTitle } from 'hooks'

import { ButtonMain } from '../../../../components/Button/Button'
import Heading from '../../../../components/Heading/Heading'
import LoadingCircle from '../../../../components/LoadingCircle/LoadingCircle'
import PhotoGalleryComponent from '../../../../components/PhotoGallery/PhotoGalleryComponent/PhotoGalleryComponent'

const PlayPhotos = ({ plays, play: paramPlay, onLoadData }) => {
  const { slug } = useParams()

  const [play, setPlay] = useState(paramPlay)

  usePageTitle(`Fotos - ${play.title}`)

  useEffect(() => {
    setPlay(paramPlay)
  }, [paramPlay])

  useEffect(() => {
    // load play if necessary
    if (play?.slug !== slug) {
      // search for play in all loaded plays
      const foundPlay = plays.find((existingPlay) => existingPlay.slug === slug)
      if (foundPlay) {
        setPlay(foundPlay)
      } else {
        // if play is not found, load individual play from backend
        onLoadData(slug)
      }
    }
  }, [onLoadData, play?.slug, plays, slug])

  if (!play?.title) return <LoadingCircle />

  return (
    <>
      <Heading title='Fotogalerie' topTitle={play.title} />
      <PhotoGalleryComponent photoItems={play.acf.fotos} />
      <ButtonMain type='link' link={`/stuecke/${play.slug}`} centered={true}>
        Zurück zum Stück
      </ButtonMain>
    </>
  )
}

export default PlayPhotos
