import { FETCH_PAGES_OVERVIEW, FETCH_PAGE_INDIVIDUAL } from '../actions/types'

const initialState = {
  pages: [],
  page: {}
}

export const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAGES_OVERVIEW:
      return {
        ...state,
        pages: action.payload
      }
    case FETCH_PAGE_INDIVIDUAL:
      return {
        ...state,
        page: action.payload
      }
    default:
      return state
  }
}
