import React, { useState } from 'react'

import classNames from 'classnames'
import { Collapse } from 'react-collapse'

import styles from './CollapseContent.module.scss'
import { isDesktop } from '../../helpers/ResponsiveHelper'

const CollapseContent = ({
  children,
  isOpened,
  header,
  styling,
  openStateObject
}) => {
  const initialOpenState = () => {
    switch (isOpened) {
      case 'desktopOnly':
        return isDesktop()
      case 'never':
        return false
    }
  }

  const [collapseOpen, setCollapseOpen] = useState(
    openStateObject ?? initialOpenState()
  )

  return (
    <section className={styles.collapse}>
      <div
        className={classNames(styles.collapse__header, styling)}
        onClick={() => {
          setCollapseOpen((currentOpenState) => !currentOpenState)
        }}
      >
        <i
          className={classNames(styles.collapse__icon, 'fa', {
            'fa-angle-up': collapseOpen,
            'fa-angle-down': !collapseOpen
          })}
        />
        {header}
      </div>
      <Collapse isOpened={collapseOpen}>{children}</Collapse>
    </section>
  )
}

export const CollapseFilter = (props) => (
  <CollapseContent {...props} styling={styles.collapse__filter} />
)

export default CollapseContent
