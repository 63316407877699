import BasicInput from './BasicInput'

const TextInput = ({
  className,
  label,
  onChange,
  value,
  name,
  placeholder
}) => {
  return (
    <BasicInput className={className} label={label} htmlFor={name}>
      <input
        type='text'
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
      />
    </BasicInput>
  )
}

export default TextInput
