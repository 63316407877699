import styled from 'styled-components'

import { SocialLinkItem } from 'types'

const SocialLinksList = styled.ul`
  display: flex;
  align-items: flex-end;

  @media ${({ theme }) => theme.devices.tablet} {
    display: none;
  }
`

const SocialLinksListItem = styled.li`
  padding: ${({ theme }) => theme.spacing.tiny};

  a i {
    color: ${({ theme }) => theme.colors.grey};

    &:hover,
    &[state='hover'] {
      color: ${({ theme }) => theme.colors.highlight};
    }
  }
`

type SocialLinksProps = {
  socialLinks: SocialLinkItem[]
}

export const SocialLinks: React.FC<SocialLinksProps> = ({ socialLinks }) => {
  if (socialLinks.length === 0) return <></>

  return (
    <SocialLinksList>
      {socialLinks.map((item) => (
        <SocialLinksListItem key={item.id}>
          <a
            href={item.socialLinkFields.link.url}
            target={item.socialLinkFields.link.target}
            rel='noreferrer'
          >
            <i
              className={item.socialLinkFields.icon}
              title={item.socialLinkFields.link.title}
            />
          </a>
        </SocialLinksListItem>
      ))}
    </SocialLinksList>
  )
}
