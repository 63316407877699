import React from 'react'
import styles from './PhotoGalleryItem.module.scss'

const PhotoGalleryItem = ({ item }) => {
  return (
    <a
      className={styles['photo-gallery-item'] + ' gallery-item'}
      data-src={item.url}
    >
      <img
        className={styles['photo-gallery-item__image'] + ' img-responsive'}
        src={item.thumbnail}
      />
    </a>
  )
}

export default PhotoGalleryItem
