import { connect } from 'react-redux'
import { fetchPostsOverview } from '../../../../actions/posts-actions'
import NewsOverview from './NewsOverview'

const mapStateToProps = (state) => {
  return state.posts
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(fetchPostsOverview())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsOverview)
