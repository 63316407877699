import { connect } from 'react-redux'
import Search from './Search'
import {
  fetchPlaysOverview,
  fetchSearchResults
} from '../../../actions/plays-actions'

const searchOptions = {
  offset: 0,
  limit: 10
}

const mapStateToProps = (state) => {
  return state.plays
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadPlays: () => {
      dispatch(fetchPlaysOverview())
    },
    onSearch: (formValues, correction = null) => {
      const spellingCorrection = correction
        ? {
            right: correction,
            wrong: searchOptions.formValues['search-text']
          }
        : {}
      searchOptions.offset = 0
      searchOptions.formValues = { ...searchOptions.formValues, ...formValues }
      dispatch(fetchSearchResults(searchOptions, spellingCorrection))
    },
    onLoadMore: () => {
      searchOptions.offset += searchOptions.limit
      dispatch(fetchSearchResults(searchOptions))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
