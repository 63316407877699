import React from 'react'
import Popup from 'reactjs-popup'

const PopupContent = ({ popupState, headingStatus, faIconClass, text }) => (
  <div className='popup-content__text'>
    <p
      className={`popup-content__heading popup-content__heading--status-${headingStatus}`}
    >
      <i className={`popup-content__icon ${faIconClass}`} />
      &nbsp;{text}
    </p>
    <div dangerouslySetInnerHTML={{ __html: popupState.message }} />
  </div>
)

const PopupButton = ({ popupState, children }) => (
  <Popup trigger={<div>{children}</div>} position='bottom center'>
    {popupState?.status === 'success' && (
      <PopupContent
        popupState={popupState}
        headingStatus='success'
        faIconClass='fas fa-check-circle'
        text='ERFOLGREICH!'
      />
    )}
    {popupState?.status === 'error' && (
      <PopupContent
        popupState={popupState}
        headingStatus='warning'
        faIconClass='fas fa-exclamation-circle'
        text='FEHLER!'
      />
    )}
  </Popup>
)

export default PopupButton
