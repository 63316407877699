import GalleryComponent from '../../../../../components/Gallery/GalleryComponent/GalleryComponent'
import DatetimeHelper from '../../../../../helpers/DatetimeHelper'
import LoadingCircle from '../../../../../components/LoadingCircle/LoadingCircle'

const ActivitiesOverview = ({ activities }) => {
  if (!activities.length) return <LoadingCircle />

  return (
    <section className='activities maxwidthcontainer sectioncontainer'>
      <h2>Aktivitäten</h2>
      <GalleryComponent
        galleryItems={activities.map((activity) => ({
          id: activity.id,
          title: activity.acf.name,
          subtitle: new DatetimeHelper(activity.acf.zeitraum.von).getYear(),
          url: activity.featuredImage?.node?.sourceUrl,
          routeTo: `/aktivitaten/${activity.slug}`
        }))}
      />
    </section>
  )
}
export default ActivitiesOverview
