export default class DatetimeHelper {
  constructor(datetime) {
    this.datetime = datetime instanceof Date ? datetime : new Date(datetime)
  }

  getShortDateString(showYear = true) {
    let dateString = `${this.getDayOfMonth(true)}.${this.getMonth(true)}.`

    if (showYear) dateString += this.getYear()

    return dateString
  }

  getLongDateString() {
    return this.getDayOfWeek() + ', ' + this.getShortDateString()
  }

  getDayOfWeek() {
    const weekdays = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag'
    ]

    return weekdays[this.datetime.getDay()]
  }

  getDayOfMonth(twoDigits = false) {
    return twoDigits
      ? ('0' + this.datetime.getDate()).slice(-2)
      : this.datetime.getDate()
  }

  getTime() {
    return this.datetime.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  getMonth(twoDigits = false) {
    return twoDigits
      ? ('0' + (this.datetime.getMonth() + 1)).slice(-2)
      : this.datetime.getMonth() + 1
  }

  getMonthName() {
    const months = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ]

    return months[this.datetime.getMonth()]
  }

  getYear() {
    return this.datetime.getFullYear()
  }
}
