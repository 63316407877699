import React from 'react'
import { ButtonMain } from '../../../../components/Button/Button'
import UpcomingTicket from '../UpcomingTicket/UpcomingTicket'
import styles from './UpcomingPlay.module.scss'
import { Link } from 'react-router-dom'
import LoadingCircle from '../../../../components/LoadingCircle/LoadingCircle'
import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import {
  getTheaterTypColor,
  getTheaterTypEmoji
} from '../../../../helpers/TheaterTypHelper'

const UpcomingPlay = ({ play }) => {
  if (!play?.title) return <LoadingCircle />

  return (
    <div className={styles['upcoming-play-container']}>
      <article className={styles['upcoming-play']}>
        <LightGallery
          plugins={[lgThumbnail, lgZoom]}
          mode='lg-fade'
          mobileSettings={{
            controls: false,
            download: false,
            showCloseIcon: true
          }}
          licenseKey='gplv3'
        >
          <div
            data-src={play.acf.beitragsbild.url}
            style={
              play.acf.beitragsbild.url
                ? {
                    backgroundImage: `url(${play.acf.beitragsbild.url})`,
                    backgroundSize: 'cover'
                  }
                : {}
            }
            className={styles['upcoming-play__image']}
          ></div>
        </LightGallery>

        <div className={styles['upcoming-play__body']}>
          <div className={styles['upcoming-play__content-heading']}>
            <div
              className={styles['upcoming-play__content-heading-icon']}
              style={{
                backgroundColor: getTheaterTypColor(play.acf.typ)
              }}
            >
              {getTheaterTypEmoji(play.acf.typ)}
            </div>
            <div>
              <div className='p--style-bold'>
                {play.acf.typ} {play.acf.jahr}
              </div>
              <div>
                {play.acf.genre} von {play.acf.autor}
              </div>
            </div>
          </div>
          <Link to={`/stuecke/${play.slug}`}>
            <h2>{play.title}</h2>
          </Link>
          <div
            className='wordpress-html'
            dangerouslySetInnerHTML={{ __html: play.acf.aktuelleInfos }}
          />
          <ButtonMain
            type='link'
            link={`/stuecke/${play.slug}`}
            centered={true}
          >
            Mehr zum Stück
          </ButtonMain>
        </div>

        <div className={styles['upcoming-play__tickets']}>
          {play.acf.auffuhrungen.map((auffuhrung) => (
            <UpcomingTicket
              key={auffuhrung.zeitpunkt}
              auffuhrung={auffuhrung}
              ticketsAb={play.acf.ticketsAb}
            />
          ))}
        </div>
      </article>
    </div>
  )
}

export default UpcomingPlay
