import React from 'react'
import styles from './Hero.module.scss'
import classNames from 'classnames'

import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import lgThumbnail from 'lightgallery/plugins/thumbnail'

import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-zoom.scss'
import 'lightgallery/css/lg-thumbnail.css'

export const HomeHero = ({ title, isLoading, children }) => {
  if (!isLoading && !children) return <></>

  return (
    <section className={styles['hero-background']}>
      <div
        className={classNames(
          styles.hero,
          styles['hero--home'],
          isLoading ? '' : styles['hero--smaller']
        )}
      >
        {isLoading && (
          <>
            <h1
              className={classNames(
                styles.hero__title,
                'h1--color-white',
                'h1--style-bold'
              )}
            >
              {title}
            </h1>
            <h3
              className={classNames(styles.hero__subtitle, 'h3--color-white')}
            >
              Website wird geladen...
            </h3>
          </>
        )}
        {children}
      </div>
    </section>
  )
}

const Hero = ({ imageSrc, title, subtitle, emoji }) => {
  return (
    <section
      className={styles['hero-background']}
      style={{
        backgroundImage: `url('${imageSrc}')`
      }}
    >
      <div
        className={styles.hero}
        style={{
          backgroundImage: `url('${imageSrc}')`,
          backgroundBlendMode: 'darken',
          backgroundColor: imageSrc ? 'rgba(0, 0, 0, 0.75)' : ''
        }}
      >
        <div className={styles.hero__content}>
          <h1
            className={styles.hero__title + ' h1--color-white h1--style-bold'}
          >
            {title}
          </h1>
          {(subtitle || emoji) && (
            <p className={styles.hero__subtitle + ' p--color-white'}>
              {emoji && emoji} {subtitle && subtitle}
            </p>
          )}
        </div>

        <div className={styles.hero__image}>
          <LightGallery
            plugins={[lgThumbnail, lgZoom]}
            mobileSettings={{
              controls: false,
              download: false,
              showCloseIcon: true
            }}
            mode='lg-fade'
            licenseKey='gplv3'
          >
            <div
              className={styles['hero__image-icon'] + ' fa fa-image'}
              data-src={imageSrc}
            ></div>
          </LightGallery>
        </div>
      </div>
    </section>
  )
}

export default Hero
