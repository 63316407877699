const isLocalhost =
  location.hostname.includes('localhost') ||
  location.hostname.includes('127.0.0.1') ||
  location.hostname.includes('192.168.') ||
  location.hostname.includes('172.')

const useLocalData = isLocalhost

const baseUrl = useLocalData
  ? 'http://theaterbergheim.localhost'
  : 'https://wp.theaterbergheim.at'

export const restUrl = baseUrl + '/wp-json/'
export const graphqlUrl = baseUrl + '/graphql/'
export const solrAppUrl = `https://solr-app.theaterbergheim.at/`
