import styled from 'styled-components'

export const MaxWidthContainer = styled.div`
  max-width: 80em;
  margin: 0 auto;

  padding-inline: ${({ theme }) => theme.spacing.small};

  @media ${({ theme }) => theme.devices.mobile} {
    margin: 0;
  }
`
