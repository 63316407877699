import React from 'react'
import SearchItem from '../SearchItem/SearchItem'

const SearchComponent = ({ searchItems, showTitles }) => (
  <section className='smallcontainer'>
    {searchItems.map((item) => {
      return <SearchItem key={item.id} item={item} showTitles={showTitles} />
    })}
  </section>
)

export default SearchComponent
