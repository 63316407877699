import StatisticComponent from '../Statistic/StatisticComponent/StatisticComponent'

const PlayStatistics = ({ play }) => {
  const actorsList = []
  play.acf.personen.besetzung &&
    play.acf.personen.besetzung.forEach((item) => {
      item.spieler &&
        item.spieler.forEach((actor) => {
          if (
            !actorsList.find(
              (existingActor) => existingActor.slug === actor.slug
            )
          )
            actorsList.push(actor)
        })
    })

  const visitors = play.acf.besucherGesamt
    ? play.acf.besucherGesamt
    : play.acf.auffuhrungen.reduce(
        (visitorsCounter, show) =>
          (visitorsCounter += parseInt(show.besucheranzahl) || 0),
        0
      )

  return (
    <StatisticComponent
      statisticItems={[
        { label: 'Aufführungsjahr', value: play.acf.jahr },
        { label: 'SpielerInnen', value: actorsList.length },
        { label: 'Aufführungen', value: play.acf.auffuhrungen.length },
        { label: 'Besucher', value: visitors }
      ]}
    />
  )
}

export default PlayStatistics
