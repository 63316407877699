import { connect } from 'react-redux'
import PlaysOverview from './PlaysOverview'
import { fetchPlaysOverview } from '../../../../actions/plays-actions'

const mapStateToProps = (state) => {
  return state.plays
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(fetchPlaysOverview())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaysOverview)
