import { connect } from 'react-redux'
import { fetchPageIndividual } from '../../../actions/page-actions'
import Imprint from './Imprint'

const mapStateToProps = (state) => {
  return { ...state.pages }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: () => dispatch(fetchPageIndividual('impressum'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Imprint)
