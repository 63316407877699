import React from 'react'
import GalleryComponent from '../Gallery/GalleryComponent/GalleryComponent'

const PlaysGallery = ({ plays }) => (
  <GalleryComponent
    galleryItems={plays.map((play) => ({
      id: play.id,
      overlay: play['person-details'],
      title: play.title,
      subtitle: play.acf.jahr,
      url: play.acf.beitragsbild?.thumbnail,
      routeTo: `/stuecke/${play.slug}`
    }))}
  />
)

export default PlaysGallery
