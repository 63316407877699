import React from 'react'

const PlayCurrentInformation = ({ currentInformation }) => {
  return (
    <section className={'smallcontainer sectioncontainer'}>
      <h2>Aktuelle Informationen</h2>
      <div
        className='wordpress-html'
        dangerouslySetInnerHTML={{ __html: currentInformation }}
      />
    </section>
  )
}

export default PlayCurrentInformation
