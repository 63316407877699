import React from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './Button.module.scss'

const Button = ({
  children,
  onClick,
  btnTypeClass,
  link = '',
  centered = false,
  type = 'button'
}) => {
  const classes = classNames(styles.btn, btnTypeClass, {
    [styles['btn--align-center']]: centered
  })

  if (type === 'button')
    return (
      <button onClick={onClick} className={classes}>
        {children}
      </button>
    )

  if (type === 'link')
    return (
      <Link to={link} className={classes}>
        {children}
      </Link>
    )

  return (
    <input type={type} onClick={onClick} className={classes} value={children} />
  )
}

export const ButtonMain = (props) => (
  <Button {...props} btnTypeClass={styles.btn__main} />
)

export const ButtonGhost = (props) => (
  <Button {...props} btnTypeClass={styles.btn__ghost} />
)

export const ButtonMedia = (props) => (
  <Button {...props} btnTypeClass={styles.btn__media}>
    <div className={classNames(props.iconClass, styles['icon-file'])} />
    <p className={classNames(styles['icon-file__text'], 'p--size-small')}>
      {props.label}
    </p>
  </Button>
)
