import {
  FETCH_PERSONS_OVERVIEW,
  FETCH_PERSON_INDIVIDUAL
} from '../actions/types'

const initialState = {
  persons: [],
  person: {}
}

export const personsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSONS_OVERVIEW:
      return {
        ...state,
        persons: action.payload
      }
    case FETCH_PERSON_INDIVIDUAL:
      return {
        ...state,
        person: action.payload
      }
    default:
      return state
  }
}
