const RadioInput = ({ className, values, group, formikValues }) => {
  return values.map((value, i) => {
    return (
      <div className={className} label={value} htmlFor={value} key={i}>
        <input
          type='radio'
          value={i}
          name={group}
          id={value}
          defaultChecked={i === 0}
          onChange={() => (formikValues[group] = i)}
        />
        <label htmlFor={value}>{value}</label>
      </div>
    )
  })
}

export default RadioInput
