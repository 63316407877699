const EmbedVideo = ({ srcUrl }) => {
  return (
    <iframe
      src={srcUrl}
      width={640}
      height={360}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen={true}
    />
  )
}

export default EmbedVideo
