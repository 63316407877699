import DatetimeHelper from '../../../../../../../helpers/DatetimeHelper'
import ShowsPastTime from '../ShowsPastTime/ShowsPastTime'
import { withStyles } from '@material-ui/core/styles'
import styles from './ShowsPastItem.module.scss'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'

const ShowsPastItem = ({ days, multipleRows, width }) => {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }))(Tooltip)

  return Array.from(days).map((value) => {
    const day = value[0]
    const dayShows = value[1]

    const date = new DatetimeHelper(day)

    const styleMultipleRows = multipleRows
      ? {
          minWidth: `${width}%`,
          display: 'flex',
          justifyContent: 'center'
        }
      : {}

    return (
      <li key={day} style={styleMultipleRows}>
        <article className={styles['show-past']}>
          <div className={styles['show-past__date']}>
            <p className='p--size-small p--color-grey'>{date.getDayOfWeek()}</p>
            <p>{`${date.getDayOfMonth()}. ${date.getMonthName()}`}</p>
          </div>

          <div className={styles['show-past__time']}>
            {dayShows.map((show) => {
              const time = new DatetimeHelper(show.zeitpunkt)

              return (
                <HtmlTooltip
                  key={show.zeitpunkt}
                  enterTouchDelay={0}
                  title={
                    <React.Fragment>
                      {show.ort.title && (
                        <p className='p--size-small p--color-white'>
                          <span className='p--style-bold p--color-white'>
                            Veranstaltungsort:{' '}
                          </span>
                          {show.ort.title}
                        </p>
                      )}

                      {show.zusatzInfo && (
                        <p className='p--size-small p--color-white'>
                          <span className='p--style-bold p--color-white'>
                            Zusatzinfo:{' '}
                          </span>
                          {show.zusatzInfo}
                        </p>
                      )}

                      {show.besucheranzahl && (
                        <p className='p--size-small p--color-white'>
                          <span className='p--style-bold p--color-white'>
                            Besucheranzahl:{' '}
                          </span>
                          {show.besucheranzahl}
                        </p>
                      )}
                    </React.Fragment>
                  }
                >
                  <ShowsPastTime time={time} />
                </HtmlTooltip>
              )
            })}
          </div>
        </article>
      </li>
    )
  })
}

export default ShowsPastItem
