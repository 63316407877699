import PhotoGalleryComponent from '../../../../../components/PhotoGallery/PhotoGalleryComponent/PhotoGalleryComponent'
import CollapseContent from '../../../../../components/CollapseContent/CollapseContent'
import { ButtonGhost } from '../../../../../components/Button/Button'

const ImageGallery = ({ images }) => {
  const maxImages = 12

  return images ? (
    <section className='gallery maxwidthcontainer'>
      <CollapseContent header={<h2>Fotogalerie</h2>} isOpened='desktopOnly'>
        <PhotoGalleryComponent photoItems={images.slice(0, maxImages)} />
        {images.length > maxImages && (
          <ButtonGhost
            type='link'
            link={location.pathname + '/fotos'}
            centered={true}
          >
            Alle {images.length} Fotos anzeigen
          </ButtonGhost>
        )}
      </CollapseContent>
    </section>
  ) : (
    <></>
  )
}

export default ImageGallery
