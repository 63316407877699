import { connect } from 'react-redux'
import { fetchPersonIndividual } from '../../../../actions/persons-actions'
import { fetchPlaysOverview } from '../../../../actions/plays-actions'
import PersonIndividual from './PersonIndividual'

const mapStateToProps = (state) => {
  return { ...state.persons, ...state.plays }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadData: (slug) => {
      dispatch(fetchPersonIndividual(slug))
      dispatch(fetchPlaysOverview())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonIndividual)
