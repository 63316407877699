import ShowsPast from './ShowsPast/ShowsPast'
import ShowsUpcoming from './ShowsUpcoming/ShowsUpcoming'

const Shows = ({ children, upcoming, ticketsAb }) =>
  upcoming ? (
    <ShowsUpcoming ticketsAb={ticketsAb}>{children}</ShowsUpcoming>
  ) : (
    <ShowsPast>{children}</ShowsPast>
  )

export default Shows
