import BasicInput from './BasicInput'

const TextInput = ({
  className,
  label,
  onChange,
  value,
  name,
  placeholder
}) => {
  return (
    <BasicInput className={className} label={label} htmlFor={name}>
      <textarea
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
      />
    </BasicInput>
  )
}

export default TextInput
